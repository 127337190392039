import { inject, Injectable } from "@angular/core";
// import { DownloadService } from "./download.service";
import { IExcelReport, IExportsArchiveItem, IExportsLink, IRequestExcelReportDTO, RecordCounted } from "common_library";
import { Observable } from "rxjs";
// import { Download } from "@ep-om/utils/download";
import { HttpClient } from "@angular/common/http";
import { format } from "date-fns";
// import { AuthQuery } from "../auth/auth.query";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { Download } from "src/app/utils/reports/download";
import { DownloadService } from "./download.service";
import { HttpIoService } from "../communication/http-io.service";
import { CommonService } from "../common.service";
import { AppService } from "../app.service";

const CONTROLLER_ROOT = 'excelReport';

@Injectable({
  providedIn: 'root'
})
export class ExportsService {
  accessToken: string;
  backendUrl;
  appService = inject(AppService);
  constructor(
    private ds: DownloadService,
    private http: HttpClient,
    private HIO: HttpIoService,
    private commonService: CommonService,
    private nzns: NzNotificationService) {


    this.ds.errors$.subscribe(err => {
      // ora è una versione semplicistica della notifica dell'errore di scaricamento
      // bisogna (prima possibile) definire un entità di base IDownloadRequest per le request di scaricamento e tutte le richieste devono estendere questa entità
      // avendoci una entità di base con 2 proprietà in comune possiamo dare un messaggio migliore sul problema occorso
      this.nzns.error("Attenzione", "Non è stato possibile effettuare lo scaricamento richiesto");
    });

    this.backendUrl = this.appService.apiUrl;
  }

  createExport(request: IRequestExcelReportDTO, fileName: string): Observable<Download> {
    return this.ds.download<IRequestExcelReportDTO>(`${this.backendUrl}/excel-export/generate-report`, request, fileName);
  }
  createXlsxTemplateSample(request: IRequestExcelReportDTO, fileName: string): Observable<Download> {
    return this.ds.download<IRequestExcelReportDTO>(`${this.backendUrl}/excel-export/create-xlsx-template`, request, fileName);
  }

  async generateExport(request: IRequestExcelReportDTO): Promise<IExportsArchiveItem> {
    console.log("Calling [create-report] ...",);
    const rv = await this.http.post<IExportsArchiveItem>(`${this.backendUrl}/excel-export/create-report`, request).toPromise();

    console.log(rv);
    return rv;
  }

  downloadFromArchive(item: IExportsArchiveItem): Observable<Download> {
    const requestTs = new Date(item.ctx.ts);
    const localFomattedOrdinableTs = format(requestTs, 'yyyy-MM-dd HH.mm.ss');
    const fileName = `${item.definition.name} - ${localFomattedOrdinableTs}.${item.definition.file}`;
    console.log(item, "->", fileName);
    return this.ds.download<IExportsArchiveItem>(`${this.backendUrl}/excel-export/archive/download`, item, fileName);
  }

  async openableUrl(item: IExportsArchiveItem): Promise<string> {

    const exportsLink: IExportsLink = await this.http.post<IExportsLink>(`${this.backendUrl}/excel-export/archive/open-link`, item).toPromise();
    console.log(exportsLink);
    const baseUrl = `${this.backendUrl}/excel-export/archive/link/${exportsLink.uid}/${exportsLink.fileName}`;
    const excel_csv = `ms-excel:ofv|u|${baseUrl}`;

    switch (item.definition.file) {
      case "pdf": return baseUrl;
      case "xlsx": return excel_csv;
      case "csv":
        if (item.definition.queries.length > 1)
          return null;
        else
          return excel_csv;
    }
  }

  downloadTemplate(id: string, template: string): Observable<Download> {
    const request = { id, template };
    return this.ds.download<typeof request>(`${this.backendUrl}/excelReport/download-template`, request, template);
  }

  async removeTemplate(id: string, template: string): Promise<boolean> {
    const rv = this.http.delete<boolean>(`/api/excelReport/remove-template/${id}/${template}`);
    return await rv.toPromise();
  }

  // questo metodo crea un tag a al volo e trigghera il click
  async openFileInNewTab(item: IExportsArchiveItem): Promise<boolean> {
    const fileUrl = await this.openableUrl(item);
    console.log(fileUrl);
    if (!fileUrl) return false;
    const link = document.createElement('a');
    link.href = fileUrl;
    link.target = '_blank';
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    return true;
  }

  // questo metodo usa window.open
  async openFromArchive(item: IExportsArchiveItem) {
    const url = await this.openableUrl(item);
    window.open(url, '_blank');
  }

  async createOrUpdateReportByForm(dto: any): Promise<IExcelReport> {
    try {
      return await this.HIO.post<IExcelReport, any>(`${CONTROLLER_ROOT}/create-update`, dto)
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  getReport(id: string): Promise<IExcelReport> {
    try {
    return this.HIO.get<IExcelReport>(`${CONTROLLER_ROOT}/get-one/${id}`);
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  getAndCountReportPaginated(pageNumber?: number, searchString?: string): Observable<RecordCounted<IExcelReport>> {
    return this.commonService.getAndCount$({
      controllerDir: 'filter-and-count',
      controllerRoot: CONTROLLER_ROOT,
      page: pageNumber,
      search: searchString
    })
  };

  async delete(queryId: string): Promise<boolean> {
    try {
      await this.HIO.deleteItem(CONTROLLER_ROOT, queryId)
      return true;
    } catch (error) {
      console.error('🐱️ : error', error);
      return false;
    }
  }

}
