import { Injectable } from '@angular/core';
import { HttpIoService } from './communication/http-io.service';
import { ISessionCommand, ISessionResult, IUser, SessionCmd, ResetTypeValues, TroubleshootingRequests } from 'common_library';
import { AuthService } from './auth/auth.service';
import { AppService } from './app.service';

const CONTROLLER_ROOT = 'core/admin';

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  backendUrl;
  constructor(private HIO: HttpIoService, private authService: AuthService, private appService: AppService) {
    this.backendUrl = this.appService.apiUrl;
   }

  /**
   * Inizia una sessione su una colonnina, con un utente
   * @param cmd
   * @param user
   * @returns
   */
  async execCommand(cmd: ISessionCommand, user: IUser) {
    const requestUserId = this.authService.state.user.id;

    if (cmd.cmd === SessionCmd.Reserve || cmd.cmd === SessionCmd.StartCharge) {
      cmd.createdBy = requestUserId
    } else {
      cmd.updatedBy = requestUserId
    }
    // Set the change source, in cmd object
    return await this.HIO.post<ISessionResult, {
      cmd: ISessionCommand,
      user: IUser,
    }>(`${CONTROLLER_ROOT}/execSessionCommand`, {
      cmd,
      user
    });
  }
  /**
   *
   * @param command
   * @param stationId
   * @param type
   * @param connectorId
   * @param body
   * @returns
   */
  async troubleshooting(command: string, stationId: string, type?: any, connectorId?: number, body?: any): Promise<any> {
    try {
      return this.HIO.post<any, any>(`${CONTROLLER_ROOT}/troubleshooting`, {
        command,
        stationId,
        type,
        connectorId,
        sessionId: body?.sessionId,
        body
      });
    } catch (error) {
      console.error('🐸: error -> ', error);
    }
  };
  async registerRfid(idTag: string, userId: string, stationId: string): Promise<any> {
    try {
      return this.HIO.post(`${CONTROLLER_ROOT}/register-rfid/${stationId}`, { idTag, userId, stationId });
    } catch (error) {
      console.error('🐸: error -> ', error);
    };
  }

  async stationReset(id: string, type: ResetTypeValues) {
    const result = await this.troubleshooting(
      TroubleshootingRequests.Reset,
      id,
      type
    );
    return result;
  }
}
