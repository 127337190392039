<div class="page page-export-detail">
  <div nz-col>
    <app-detail-header [label]="pageTitle()" [isRefreshActive]="false"> </app-detail-header>
  </div>
  <div>
    <div nz-row>
      <div nz-col nzXs="48" nzSm="48" nzMd="48" nzLg="24" nzXl="24" nzXxl="24" class="page-section">
        <app-block-header [title]="blockHeaderTitle()" [isRefreshActive]="false" icon="export"></app-block-header>
        <form nz-form nzLayout='vertical' [formGroup]="form" class="form">
            <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
          <div nz-col>
            <div nz-row nzJustify="end" [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
              <div nz-col nzXs="8">
                <button class="btn" nz-button nzBlock (click)="reset()">{{'BUTTONS.CANCEL'|translate}}</button>
              </div>
              <div nz-col nzXs="8">
                <button class="btn" nz-button nzBlock type="submit" nzType="primary" (click)="submitForm()"
                  [disabled]="form.invalid || !form.dirty">{{'BUTTONS.SAVE'|translate}}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>


  <!-- <pre>Valida:{{ form.valid }} Modificata (DIRTY):{{ form.dirty }} Incontaminata (PRISTINE):{{ form.pristine }}</pre>
  <pre>{{ model | json }}</pre> -->

  <div class="flex flex-row content-center gap-2 mb-10 mr-15">
    <button nz-button nzType="primary" (click)="this._testerOpen$.next(true)" nzSize="large" class="text-icons"><span nz-icon nzType="sliders"></span>Tester</button>
    <nz-alert *ngIf="checkOk$ | async" nzType="success" nzMessage="{{'REPORT.PARAMETER_CONFIGURATION_CORRECT'|translate}}" nzShowIcon></nz-alert>
    <nz-alert *ngIf="this.loading$ | async" nzType="info" nzMessage="{{'PHRASE.LOADING'|translate}}" nzShowIcon></nz-alert>
    <nz-alert *ngIf="this.err$ | async as error" nzType="error" [nzMessage]="error" nzShowIcon></nz-alert>
  </div>

  <div *ngIf="!!this.model?.id" class="form-section-container mb-20">

    <div class="form-section-header">Template</div>

    <div class="flex flex-row flex-wrap w-full">

      <div *ngFor="let template of this.templates$ | async">
        <nz-tag *ngIf="!!template"  class="cursor-pointer mb-10" nzMode="closeable" (nzOnClose)="removeTemplate($event,template)" (click)="downloadTemplate(template)">{{ template }}</nz-tag>
      </div>

      <nz-upload nzAccept=".xlsx" [nzAction]="uploadUrl()" [nzMultiple]="false" [nzShowUploadList]="false" (nzChange)="handleUploadEvents($event)">
        <nz-tag nzColor="blue">
          <i nz-icon nzType="upload"></i>
          {{'BUTTONS.ADD'|translate}}
        </nz-tag>
      </nz-upload>

    </div>

    <nz-progress *ngIf="downloadTemplate$ | async as download" [nzPercent]="download.progress" nzType="circle" [nzWidth]="18"></nz-progress>

  </div>

  <!-- FV: mi rendo conto che è un po' complicato ... mettiamoci le istruzioni per l'uso ! -->
  <nz-collapse class="mb-10 mr-15 mb-10">
    <nz-collapse-panel [nzHeader]="howToHeader" [nzExtra]="howToExtra">
      <ng-template #howToHeader><i nz-icon nzType="info-circle" nzTheme="outline" class="align-middle mr-10"></i> <b class="text-blue-600 ml-10">{{'REPORT.CONFIGURE_EXPORT'|translate}}</b></ng-template>
      <ng-template #howToExtra><i nz-icon nzType="book" nzTheme="outline"></i></ng-template>
      <b>{{'REPORT.DEFINITION'|translate}}</b>
      <p class="text-xs">
        A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome
        guest in many households across the world.
      </p>
      <b>{{'REPORT.FORMAT'|translate}}</b>
      <p class="text-xs">
        A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome
        guest in many households across the world.
      </p>
      <b>{{'REPORT.VISIBILITY'|translate}}</b>
      <p class="text-xs">
        A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome
        guest in many households across the world.
      </p>
      <b>{{'REPORT.DATA'|translate}}</b>
      <p class="text-xs">
        A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome
        guest in many households across the world.
      </p>
      <b>{{'REPORT.TEMPLATE'|translate}}</b>
      <p class="text-xs">
        A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome
        guest in many households across the world.
      </p>
    </nz-collapse-panel>
  </nz-collapse>

</div>

<nz-drawer
  [nzWidth]="'80%'"
  [nzVisible]="testerOpen$ | async"
  nzPlacement="right"
  nzTitle="{{'REPORT.EXPORT_TESTER'|translate}}"

  (nzOnClose)="this._testerOpen$.next(false)"
>
  <ng-container *nzDrawerContent>

    <div *ngIf="this.tester">
      <form nz-form nzLayout='vertical' [formGroup]="this.tester.format.form">
        <formly-form [model]="this.tester.format.model" [fields]="this.tester.format.fields" [options]="this.tester.format.options" [form]="this.tester.format.form"></formly-form>
      </form>
    </div>

    <div *ngIf="this.tester?.params" class="w-full flex flex-row justify-between gap-4">

      <div class="w-full">
        <form nz-form nzLayout='vertical' [formGroup]="this.tester.params.form">
          <formly-form [model]="this.tester.params.model" [fields]="this.tester.params.fields" [options]="this.tester.params.options" [form]="this.tester.params.form"></formly-form>
        </form>
      </div>

      <div class="terminal"><pre><small>{{ this.tester.params.model | json }}</small></pre></div>

    </div>



    <div class="mb-10 yf-flex yf-flex-row yf-gap-10">
      <button nz-button nzType="primary" (click)="doExport()" class="text-icons" [disabled]="!(!!this.tester && (!this.tester.params || this.tester.params.form.valid) && !!this.tester.format.form.valid)"><span nz-icon nzType="cloud-download"></span>{{'REPORT.EXPORT2'|translate}}</button>
      <button nz-button (click)="createXlsxTemplateSample()" class="text-icons" [disabled]="!(!!this.tester && (!this.tester.params || this.tester.params.form.valid) && !!this.tester.format.form.valid)"><span nz-icon nzType="audit"></span>XLSX-Template</button>
    </div>

    <div class="w-full" *ngIf="download$ | async as download">
      <nz-progress [nzPercent]="download.progress"></nz-progress>
      <!-- <nz-alert *ngIf="!!download.status && download.status!=200" nzType="error" [nzMessage]="download.statusText" [nzDescription]="download.message" nzShowIcon></nz-alert> -->
      <!-- <pre>{{ download | json }}</pre> -->
    </div>

  </ng-container>
</nz-drawer>

