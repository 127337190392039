import { Injectable } from '@angular/core';
import { HttpIoService } from '../communication/http-io.service';
import { IMeterValue, ISession, IStatusNotification, IStopTransaction, RecordCounted, ISessionUserDTO, ISearchCriteriaSession, SelectSessionTimeValues } from 'common_library';
import { Observable } from 'rxjs';

const CONTROLLER_ROOT = 'session';

@Injectable({ providedIn: 'root' })
export class SessionService {

  constructor(private HIO: HttpIoService) { }

  async getSessionByUserId(userId: string): Promise<ISession[]> {
    const session = await this.HIO.get<ISession[]>(`session/all-user/${userId}`);
    return session;
  }

  /**
   *
   *
   * @param param oggetto che contiene tutte le variabili per gestire i vari casi
   * @returns un oggetto contenente data un array di ISession[] e count conto  totale dei record recuperati
   */
  /*
  async getSessionsByDate(param: { dateFilter?: Date[], pageNumber?: number, sessionActive?: boolean }): Promise<RecordCounted<ISession>> {
    return await this.HIO.post<RecordCounted<ISession>, any>(`${CONTROLLER_ROOT}/paginated`, { dates: param.dateFilter, pageNumber: param.pageNumber - 1, sessionActive: param.sessionActive });
  }
  */

  getSessionsByDate$(dto: ISearchCriteriaSession): Observable<RecordCounted<ISession>> {
    return this.HIO.post$<RecordCounted<ISession>, any>(`${CONTROLLER_ROOT}/paginated`, {...dto, pageIndex: dto.pageIndex - 1});
  }

  async getOneSession(id: string): Promise<ISession> {
    return await this.HIO.get<ISession>(`${CONTROLLER_ROOT}/one/${id}`)
  }
 
  async getOneSessionAdmin(id: string): Promise<ISession> {
    return await this.HIO.get<ISession>(`${CONTROLLER_ROOT}/admin/one/${id}`)
  }

  async getOneSessionAdminBlock(id: string): Promise<ISession> {
    return await this.HIO.get<ISession>(`${CONTROLLER_ROOT}/admin/block/one/${id}`)
  }

  async getSessionAdminByStationIdPaginated(stationId: string, pageIndex: number): Promise<RecordCounted<ISessionUserDTO>> {
    const session = await this.HIO.post<RecordCounted<ISession>, { pageIndex: number }>(`${CONTROLLER_ROOT}/admin/block/by-station-paginated/${stationId}`, { pageIndex: pageIndex - 1 });
    return session;
  }

  async getSessionAdminByUserIdPaginated(userId: string, pageIndex: number): Promise<RecordCounted<ISessionUserDTO>> {
    return await this.HIO.post(`${CONTROLLER_ROOT}/admin/block/by-user-paginated/${userId}`, { pageIndex: pageIndex - 1 });
  }

  async getMeterValues(id: string): Promise<IMeterValue[]> {
    return await this.HIO.get<IMeterValue[]>(`metervalue/session/${id}`);
  }

  async getStatusNotifications(id: string): Promise<IStatusNotification[]> {
    return await this.HIO.get<IStatusNotification[]>(`statusnotification/session/${id}`);
  }

  async getStopTransactions(id: string): Promise<IStopTransaction[]> {
    return await this.HIO.get<IStopTransaction[]>(`stoptransaction/session/${id}`);
  }

  async getDistinctMetervalues(sessionId: string) {
    const dMV = await this.HIO.get<any>(`metervalue/keys/${sessionId}`)
    dMV.map((s, i) => { return { id: i, name: s } })
    let arr = dMV.map((s, i) => { return { id: s, name: s } })
    return arr;
  }

  async stopSession(sessionId: string) {
    try {
      await this.HIO.get(`${CONTROLLER_ROOT}/quit/${sessionId}`)
    } catch (err) {
      console.error(err);
    }
  }
}
