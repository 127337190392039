import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

import { NavigationEnd, Router } from '@angular/router';
import { _ } from 'src/app/consts';
import { AppService, log } from 'src/app/services/app.service';
import { StorageService } from 'src/app/services/storage.service';
import { LOCAL_STORAGE_DATE_RANGE } from '../sessions/sessions.component';
import { LOCAL_STORAGE_SESSIONS } from '../sessions/sessions.component';
import { BehaviorSubject, Observable, Subscription, filter, map, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from '../../services/translateConfig.service';
import { UserService } from 'src/app/services/entities/user.service';
import { IGestore, IGestoreUser, IUser, Language } from 'common_library';
import { ISideMenuItem, SideMenuAboutItems, SideMenuContentItems, SideMenuItems } from 'common_library';
import { HttpIoService } from 'src/app/services/communication/http-io.service';
import { GestoreService } from 'src/app/services/entities/gestore.service';
import { SideMenuReportsItems } from 'common_library';



export enum Path {
  Legend = 'legend',
  Versions = 'versions',
  Chapter = 'chapter',
  Sections = 'section',
  Content = 'content',
  Multimedia = 'multimedia',
}

@Component({
  selector: 'app-authenticated',
  templateUrl: './authenticated.component.html',
  styleUrls: ['./authenticated.component.scss',
    './../../../../node_modules/flag-icons/css/flag-icons.min.css'
  ]
})

export class AuthenticatedComponent implements OnInit {

  isCollapsed = false;
  avatarInitials$: Observable<string>;
  sideMenuItems: ISideMenuItem[] = [
    {
      id: SideMenuItems.WELCOME,
      name: 'MENU.DASHBOARD',
      routerLink: '/authenticated/welcome',
      icon: 'home',
      visible: false
    },
    {
      id: SideMenuItems.USERS,
      name: 'MENU.USERS',
      routerLink: "/authenticated/users",
      icon: "team",
      visible: false
    },
    {
      id: SideMenuItems.GESTORE,
      name: 'MENU.MANAGERS',
      routerLink: "/authenticated/gestore",
      icon: "user",
      visible: false
    },
    {
      id: SideMenuItems.MAP,
      name: 'MENU.MAP',
      routerLink: "/authenticated/map",
      icon: "global",
      visible: false
    },
    {
      id: SideMenuItems.INSTALLATIONS,
      name: 'MENU.INSTALLATIONS',
      routerLink: "/authenticated/installations",
      icon: "cluster",
      visible: false
    },
    {
      id: SideMenuItems.STATIONS,
      name: 'MENU.STATIONS',
      routerLink: "/authenticated/stations",
      icon: "usb",
      visible: false
    },
    {
      id: SideMenuItems.PODS,
      name: 'MENU.POD',
      routerLink: "/authenticated/pods",
      icon: "one-to-one",
      visible: false
    },
    {
      id: SideMenuItems.SESSIONS,
      name: 'MENU.SESSIONS',
      routerLink: "/authenticated/sessions",
      icon: "group",
      visible: false
    },
    {
      id: SideMenuItems.CHARGE_DETAIL_RECORD,
      name: 'MENU.CDR',
      routerLink: "/authenticated/charge-detail-record",
      icon: "barcode",
      visible: false
    },
    {
      id: SideMenuItems.PAYMENTS,
      name: 'MENU.PAYMENTS',
      routerLink: "/authenticated/payments",
      icon: "credit-card",
      visible: false
    },
    {
      id: SideMenuItems.COST_REVENUE_SPLIT,
      name: 'MENU.INCOME',
      routerLink: "/authenticated/cost-revenue-split",
      icon: "euro-circle",
      visible: false
    },
    {
      id: SideMenuItems.INVOICE,
      name: 'MENU.BILL',
      routerLink: "/authenticated/invoice",
      icon: "file-text",
      visible: false
    },
    {
      id: SideMenuItems.TARIFFS,
      name: 'MENU.RATE',
      routerLink: "/authenticated/tariffs",
      icon: "euro-circle",
      visible: false
    },
    {
      id: SideMenuItems.GROUPS,
      name: 'MENU.GROUPS',
      routerLink: "/authenticated/groups",
      icon: "ungroup",
      visible: false
    },
    {
      id: SideMenuItems.GATEWAYS,
      name: 'MENU.GATEWAY',
      routerLink: "/authenticated/gateway",
      icon: "apartment",
      visible: false
    }
  ]
  sideMenuItems$: BehaviorSubject<ISideMenuItem[]> = new BehaviorSubject([
    {
      id: SideMenuItems.WELCOME,
      name: 'MENU.DASHBOARD',
      routerLink: '/authenticated/welcome',
      icon: 'home',
      visible: false
    },
    {
      id: SideMenuItems.USERS,
      name: 'MENU.USERS',
      routerLink: "/authenticated/users",
      icon: "team",
      visible: false
    },
    {
      id: SideMenuItems.GESTORE,
      name: 'MENU.MANAGERS',
      routerLink: "/authenticated/gestore",
      icon: "user",
      visible: false
    },
    {
      id: SideMenuItems.MAP,
      name: 'MENU.MAP',
      routerLink: "/authenticated/map",
      icon: "global",
      visible: false
    },
    {
      id: SideMenuItems.INSTALLATIONS,
      name: 'MENU.INSTALLATIONS',
      routerLink: "/authenticated/installations",
      icon: "cluster",
      visible: false
    },
    {
      id: SideMenuItems.STATIONS,
      name: 'MENU.STATIONS',
      routerLink: "/authenticated/stations",
      icon: "usb",
      visible: false
    },
    {
      id: SideMenuItems.PODS,
      name: 'MENU.POD',
      routerLink: "/authenticated/pods",
      icon: "one-to-one",
      visible: false
    },
    {
      id: SideMenuItems.SESSIONS,
      name: 'MENU.SESSIONS',
      routerLink: "/authenticated/sessions",
      icon: "group",
      visible: false
    },
    {
      id: SideMenuItems.CHARGE_DETAIL_RECORD,
      name: 'MENU.CDR',
      routerLink: "/authenticated/charge-detail-record",
      icon: "barcode",
      visible: false
    },
    {
      id: SideMenuItems.PAYMENTS,
      name: 'MENU.PAYMENTS',
      routerLink: "/authenticated/payments",
      icon: "credit-card",
      visible: false
    },
    {
      id: SideMenuItems.COST_REVENUE_SPLIT,
      name: 'MENU.INCOME',
      routerLink: "/authenticated/cost-revenue-split",
      icon: "euro-circle",
      visible: false
    },
    {
      id: SideMenuItems.INVOICE,
      name: 'MENU.BILL',
      routerLink: "/authenticated/invoice",
      icon: "file-text",
      visible: false
    },
    {
      id: SideMenuItems.TARIFFS,
      name: 'MENU.RATE',
      routerLink: "/authenticated/tariffs",
      icon: "euro-circle",
      visible: false
    },
    {
      id: SideMenuItems.GROUPS,
      name: 'MENU.GROUPS',
      routerLink: "/authenticated/groups",
      icon: "ungroup",
      visible: false
    },
    {
      id: SideMenuItems.GATEWAYS,
      name: 'MENU.GATEWAY',
      routerLink: "/authenticated/gateway",
      icon: "apartment",
      visible: false
    }
  ])
  contentSubMenuItems: ISideMenuItem[] = [
    {
      id: SideMenuContentItems.INFO_CONTENT,
      name: 'MENU.MANAGEMENT',
      routerLink: "/authenticated/info-content",
      icon: "file-done",
      visible: false,
    },
    {
      id: SideMenuContentItems.INFO_CHAPTER,
      name: 'MENU.CHAPTERS',
      routerLink: "/authenticated/info-chapter",
      icon: "file-text",
      visible: false,
    },
    {
      id: SideMenuContentItems.INFO_SECTION,
      name: 'MENU.SECTIONS',
      routerLink: "/authenticated/info-section",
      icon: "align-left",
      visible: false,
    },
    {
      id: SideMenuContentItems.INFO_MULTIMEDIA,
      name: 'MENU.MULTIMEDIA',
      routerLink: "/authenticated/info-multimedia",
      icon: "file-image",
      visible: false,
    }
  ]
  // reportsSubMenuItems: ISideMenuItem[] = [
  //   {
  //     id: "reports-query",
  //     name: this.translate.instant("REPORT.QUERY"),
  //     routerLink: "/authenticated/reports-query",
  //     icon: "database",
  //     visible: false,
  //   },
  //   {
  //     id: "reports-export",
  //     name: 'Export configuration',
  //     routerLink: "/authenticated/reports-export",
  //     icon: "export",
  //     visible: false,
  //     subMenu: [
  //       {
  //         id: 'reports-export-config',
  //         name: this.translate.instant("REPORT.CONFIGURATION"),
  //         routerLink: "/authenticated/reports-export/list",
  //         visible: false,
  //       },
  //       {
  //         id: 'reports-export-new',
  //         name: 'REPORT.NEW',
  //         routerLink: "/authenticated/reports-export/new-export",
  //         visible: false,
  //       },
  //       {
  //         id: 'reports-export-list',
  //         name: 'REPORT.ARCHIVE',
  //         routerLink: "/authenticated/reports-export/archive",
  //         visible: false,
  //       }
  //     ]
  //   },
  // ]

  reportsSubMenuItems: ISideMenuItem[] = this.getUpdatedFields();
  aboutSubMenuItems: ISideMenuItem[] = [
    {
      id: SideMenuAboutItems.ABOUT_LEGENDA,
      name: "MENU.LEGEND",
      routerLink: "/authenticated/about/legenda",
      icon: "unordered-list",
      visible: false,
    },
    {
      id: SideMenuAboutItems.ABOUT_VERSIONS,
      name: 'MENU.VERSIONS_LOG',
      routerLink: "/authenticated/about/versions",
      icon: "tag",
      visible: false,
    }
  ]

  private langChangeSubscription: Subscription;


  version: string = _.VERSION;
  loading$ = new BehaviorSubject<boolean>(false);
  isAdmin$ = new BehaviorSubject<boolean>(false);
  selectedLanguage: Language; // Lingua predefinita
  selectedLanguageItem$ = new BehaviorSubject(null); // Lingua select item

  selectedGestore: string;

  languages = [
    {
      code: 'it',
      label: this.translate.instant("LABEL.ITALIAN"),
      flag: 'it'
    },
    {
      code: 'en',
      label: this.translate.instant("LABEL.ENGLISH"),
      flag: 'gb'
    },


    // Aggiungi altre lingue se necessario
  ];

  gestori: IGestore[] = [];

  isImpersonificateModalVisible$ = new BehaviorSubject<boolean>(false);

  selectedValue: string;



  constructor(
    public auth: AuthService,
    public AS: AppService,
    public router: Router,
    private storageService: StorageService,
    private translate: TranslateService,
    private translateConfigService: TranslateConfigService,
    private userService: UserService,
    private HIO: HttpIoService,
    private gestoreService: GestoreService,
  ) {
    const savedLanguage: Language = this.getLanguageInApp();
    this.setLanguageInApp(savedLanguage);
    this.selectedLanguage = savedLanguage;
    this.selectedLanguageItem$.next(this.languages.find(c => c.code === savedLanguage));
    this.translate.use(savedLanguage);
    router.events.pipe(filter(event => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url));

    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this.reportsSubMenuItems = this.getUpdatedFields();
    });
  }

  async ngOnInit() {
    if (this.AS.isMobile) this.isCollapsed = true;
    this.isAdmin$.next(this.auth.user$.value.isAdmin);

    this.gestori = await this.getManagersAssociated();
    if (this.gestori.length > 0) {
      this.selectedGestore = this.auth.user$.value.settings.gestoreSelected
    }
    await this.updateSideMenu(this.selectedGestore);

    this.avatarInitials$ = this.auth.user$.pipe(
      filter(user => !!user),
      map((user) => {
        return `${user.name[0]}${user.surname[0]}`;
      }));
  }

  /**
 * questo metodo compara gli item del menu laterale che ci sono di default
 * con quelli che vengono recuperati dal backend sulla base dei ruoli dell'utente
 * e imposta la visibilità sulla base di questi ultimi
 */
  async hideSideMenuItems(gestoreId?: string) {
    this.loading$.next(true);
    const newSideMenuItems = await this.HIO.get<ISideMenuItem[]>(`utility/admin/side-menu-items-visibility/${gestoreId}`);
    const sideMenuMap = newSideMenuItems.map(newSide => {
      const originalSideMenu = this.sideMenuItems.find(v => v.id === newSide.id)
      return {
        ...originalSideMenu,
        ...newSide
      }
    })
    this.sideMenuItems$.next(
      sideMenuMap
    )
    this.loading$.next(false);
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['/login']);
    this.storageService.remove(LOCAL_STORAGE_DATE_RANGE)
    this.storageService.remove(LOCAL_STORAGE_SESSIONS)
  }

  onLanguageChange(langCode: Language) {
    this.translate.use(langCode);
    this.setLanguageInApp(langCode);
  }


  getLanguageInApp(): Language {
    const user = this.auth.user$.value;
    // Se l'utente ha la lingua salvata nel DB, imposto quella
    if (user?.lang?.admin) {
      return user.lang.admin;
    }
    // Altrimenti provo a vedere quella salvata nel local storage
    return this.storageService.get<string>('_YF_LANG') as Language || "en";
  }

  async setLanguageInApp(langCode: Language) {
    const user = this.auth.user$.value;

    this.storageService.set('_YF_LANG', langCode);
    this.translateConfigService.setLanguage(langCode);
    this.selectedLanguageItem$.next(this.languages.find(c => c.code === langCode));

    await this.userService.updateUser(this.auth.user$.value.id, {
      ...user,
      lang: {
        admin: langCode,
      }
    });
  }

  async getManagersAssociated() {
    const userId = this.auth.user$.value.id;
    const gestoriAssociated = await this.gestoreService.getGestoreByUserId(userId);
    return gestoriAssociated;
  }

  async onGestoreChange(selectedId: string) {
    this.selectedGestore = selectedId;
    const user = this.auth.user$.value;
    await this.userService.updateUser(user.id, { ...user, settings: { ...user.settings, gestoreSelected: selectedId } });
    await this.updateSideMenu(selectedId);
    await this.gestoreService.setSelectedGestore(selectedId);
    await this.auth.refresh();
    this.goToDashboardOrReloadIt();
  }

  async updateSideMenu(gestoreId?: string) {
    await this.hideSideMenuItems(gestoreId);
  }

  goToDashboardOrReloadIt() {
    if (this.router.url === '/authenticated/welcome') {
      window.location.reload();
    } else {
      this.router.navigate([`/authenticated/welcome`]);
    }
  }

  showModalImpersonificate() {
    this.isImpersonificateModalVisible$.next(true);
  }

  hideImpersonation() {
    this.isImpersonificateModalVisible$.next(false);
  }

  async onValueSelected(value: string): Promise<void> {
    this.selectedValue = value;
    const rv = await this.auth.impersonate(this.selectedValue);
    // se ok, mi tornano giù anche i token da utilizzare. faccio logout e login con i token ricevuti
    if (rv) {
      //await this.auth.logoutForImpersonate();

      let currentClientId = this.storageService.get(_.CLIENT_KEY);
      this.storageService.clear();
      this.storageService.set(_.CLIENT_KEY, currentClientId);
      await this.auth.setTokenData(rv);
    }
    return;
  }

  getUpdatedFields(): ISideMenuItem[] {
    return [
      {
        id: "reports-query",
        name: this.translate.instant("REPORT.QUERY"),
        routerLink: "/authenticated/reports-query",
        icon: "database",
        visible: false,
      },
      {
        id: "reports-export",
        name: 'Export configuration',
        routerLink: "/authenticated/reports-export",
        icon: "export",
        visible: false,
        subMenu: [
          {
            id: 'reports-export-config',
            name: this.translate.instant("REPORT.CONFIGURATION"),
            routerLink: "/authenticated/reports-export/list",
            visible: false,
          },
          {
            id: 'reports-export-new',
            name: this.translate.instant("REPORT.NEW"),
            routerLink: "/authenticated/reports-export/new-export",
            visible: false,
          },
          {
            id: 'reports-export-list',
            name: this.translate.instant("REPORT.ARCHIVE"),
            routerLink: "/authenticated/reports-export/archive",
            visible: false,
          }
        ]
      },
    ]
  }

  ngOnDestroy() {
    this.langChangeSubscription.unsubscribe();
  }

  collapseMobile() {
    this.AS.isMobile ? this.isCollapsed = !this.isCollapsed : null
  }

}
