import { Injectable } from '@angular/core';
import { HttpIoService } from '../communication/http-io.service';
import { IBankTransaction, IError, ISearchCriteriaPayments, RecordCounted } from 'common_library';
import { Observable } from 'rxjs';
import { CommonService } from '../common.service';

const CONTROLLER_ROOT = "bank-transaction";

@Injectable({
  providedIn: 'root'
})
export class BankTransactionService {

  constructor(private HIO: HttpIoService, private commonService: CommonService) { }

  async getOneBankTransaction(bankTransactionId: number): Promise<IBankTransaction> {
    return this.HIO.get(`${CONTROLLER_ROOT}/one/${bankTransactionId}`);
  }

  async getOneBankTransactionAdminBlock(bankTransactionId: number): Promise<IBankTransaction> {
    return this.HIO.get(`${CONTROLLER_ROOT}/admin/block/one/${bankTransactionId}`);
  }

  getPaymentsCountedAndPaginatedByState(dto: ISearchCriteriaPayments): Observable<RecordCounted<IBankTransaction>> {
    try {
      const rv = this.HIO.post$<RecordCounted<IBankTransaction>, any>(`${CONTROLLER_ROOT}/admin/find-filtered-paginated-by-state`, {
        pageIndex: dto.pageIndex-1,
        search: dto.searchString,
        sorting: dto.sorting,
        paymentState: dto.paymentState,
      })
      return rv;
    } catch (error) {
      console.error("🐸, error: ", error);
    }
  }

  public async tryPaymentAgain(bankTransactionId: number): Promise<boolean> {
    try {
      return this.HIO.post(`${CONTROLLER_ROOT}/retry/${bankTransactionId}`, {})
    } catch (error) {
      console.error("🐸, error: ", error);
    }
  }

  public async forcePaymentForCdr(cdrId: string): Promise<boolean | IError> {
    try {
      return this.HIO.post(`${CONTROLLER_ROOT}/try-cdr-payment`, { cdrId })
    } catch (error) {
      console.error("🐸, error: ", error);
    }
  }

  async getBankTransactionsAdminBlockBySessionId(sessionId: string): Promise<IBankTransaction[]> {
    try {
      return this.HIO.get(`${CONTROLLER_ROOT}/admin/block/by-session/${sessionId}`);
    } catch (error) {
      console.error("🐸, error: ", error);
    }
  }

  async getBankTransactionAdminBlockByCdrId(cdrId: string): Promise<IBankTransaction> {
    try {
      return this.HIO.get(`${CONTROLLER_ROOT}/admin/block/by-cdr/${cdrId}`);
    } catch (error) {
      console.error("🐸, error: ", error);
    }
  }
}
