export type ValueOf<T> = T[keyof T];

export const SortingOptions = Object.freeze({
  ASC: "asc",
  DESC: "desc",
  NEW: "new",
  OLD: "old"
});
export type SortingOptionsValues = ValueOf<typeof SortingOptions>;
export type SortingOptionsKeys = keyof typeof SortingOptions;


export const SortingOptionsShort = Object.freeze({
  NEW: "new",
  OLD: "old"
});

export type SortingOptionsShortValues = ValueOf<typeof SortingOptionsShort>;
export type SortingOptionsShortKeys = keyof typeof SortingOptionsShort;



export const SelectStationAndInstallationState = Object.freeze({
  NONE: -1,
  NOT_AVAILABLE: 0,
  AVAILABLE: 1,
  RESERVED: 2,
  IN_CHARGING: 3,
  OCCUPIED: 4,
  PREPARING: 5
});
export type SelectStationAndInstallationStateValues = ValueOf<typeof SelectStationAndInstallationState>;
export type SelectStationAndInstallationStateKeys = keyof typeof SelectStationAndInstallationState;


export const SelectSessionState = Object.freeze({
  ALL: "all",
  ACTIVE: "active",
  NOT_ACTIVE: "not-active"
});
export type SelectSessionStateValues = ValueOf<typeof SelectSessionState>;
export type SelectSessionStateKeys = keyof typeof SelectSessionState;


export const SelectSessionTime = Object.freeze({
  TODAY: "today",
  YESTERDAY: "yesterday",
  THIS_WEEK: "thisWeek",
  LAST_WEEK: "lastWeek",
  CUSTOM_RANGE: "customRange"
});
export type SelectSessionTimeValues = ValueOf<typeof SelectSessionTime>;
export type SelectSessionTimeKeys = keyof typeof SelectSessionTime;

export const SelectNumberOfConnector = Object.freeze({
  NONE: -1,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4
});
export type SelectNumberOfConnectorValues = ValueOf<typeof SelectNumberOfConnector>;
export type SelectNumberOfConnectorKeys = keyof typeof SelectNumberOfConnector;


export const SelectCpo = Object.freeze({
  NONE: "none",
  YFL: "YFL",
  ENX: "ENX",
  F2X: "F2X"
});
export type SelectCpoValues = ValueOf<typeof SelectCpo>;
export type SelectCpoKeys = keyof typeof SelectCpo;


export const SelectIsAdmin = Object.freeze({
  NONE: "none",
  TRUE: "true",
  FALSE: "false"
});
export type SelectIsAdminValues = ValueOf<typeof SelectIsAdmin>;
export type SelectIsAdminKeys = keyof typeof SelectIsAdmin;


export const SelectOnlineStationState = Object.freeze({
  NONE: "none",
  ONLINE: "online",
  OFFLINE: "offline"
});
export type SelectOnlineStationStateValues = ValueOf<typeof SelectOnlineStationState>;
export type SelectOnlineStationStateKeys = keyof typeof SelectOnlineStationState;



export const SelectCDRState = Object.freeze({
  NONE: -1,
  NEW: 0,
  IN_PROGRESS: 5,
  DONE: 6,
  FAILED: 7,
  NOT_DUE: 8,
  IN_REVIEW: 9,
  IN_REPROCESSING: 10
});
export type SelectCDRStateValues = ValueOf<typeof SelectCDRState>;
export type SelectCDRStateKeys = keyof typeof SelectCDRState;


export const SelectPaymentState = Object.freeze({
  NONE: -1,
  NEW: 0,
  IN_PROGRESS: 1,
  IN_REPROCESSING: 2,
  DONE: 3,
  FAILED: 4,
  NOT_NECESSARY: 5
});
export type SelectPaymentStateValues = ValueOf<typeof SelectPaymentState>;
export type SelectPaymentStateKeys = keyof typeof SelectPaymentState;


export const SelectCorrispettivoState = Object.freeze({
  NONE: -1,
  NEW: 0,
  IN_PROGRESS: 1,
  DONE: 2,
  FAILED: 3,
  IN_REPROCESSING: 4,
  CANCELED: 5,
  NOT_NECESSARY: 6,
  IN_CANCELLATION: 7
});
export type SelectCorrispettivoStateValues = ValueOf<typeof SelectCorrispettivoState>;
export type SelectCorrispettivoStateKeys = keyof typeof SelectCorrispettivoState;

export const SelectGroupCategory = Object.freeze({
  NONE: "none",
  USERS: "30F",
  STATIONS: "60F",
  RATE: "70F",
  VISIBILITY: "80F",
  GROUPS: "50F"
});
export type SelectGroupCategoryValues = ValueOf<typeof SelectGroupCategory>;
export type SelectGroupCategoryKeys = keyof typeof SelectGroupCategory;


export const SelectInvoiceState = Object.freeze({
  NONE: -1,
  NEW: 0,
  IN_PROGRESS: 1,
  IN_REPROCESSING: 2,
  DONE: 3,
  FAILED: 4,
  REJECTED: 5,
  NOT_NECESSARY: 6
});
export type SelectInvoiceStateValues = ValueOf<typeof SelectInvoiceState>;
export type SelectInvoiceStateKeys = keyof typeof SelectInvoiceState;