import { IBankTransaction, IBankTransaction_FE_History } from "src/bank-transaction.interface";
import { IConnector, IConnector_FE_SessionHistory } from "src/connector.interface";
import { ISessionUserDTO } from "src/dtos/admin/sessionUserDto";
import { IInstallation, IInstallation_FE_SessionHistory } from "src/installation.interface";
import { IChargeDetailRecord, IChargeDetailRecord_FE_SessionHistory } from "src/ocpi/cdr.interface";
import { ISession, ISession_FE_SessionHistory } from "src/session.interface";
import { IStation, IStation_FE_SessionHistory } from "src/station.interface";
import { IUser, IUser_FE_Login, IUser_FE_SessionHistory } from "src/user.interface";

export namespace Mapper {
  export function map_ISession_to_ISessionUserDto(session: ISession): ISessionUserDTO {
    const dest: ISessionUserDTO = {
      id: session.id,
      createdAt: session.createdAt,
      endedAt: session.endedAt,
      energy: session.energy,
      cpo: session.cpo,
      power: session.power
    }
    if (session.user) {
      dest.user = {
        id: session.user.id,
        name: session.user.name,
        surname: session.user.surname
      };
    }
    if (session.connector) {
      dest.station = {
        id: session.connector.station?.id,
        name: session.connector?.station.name,
        installation: {
          id: session.connector?.station?.installation?.id,
          name: session.connector?.station?.installation?.name,
        }
      };
    }
    return dest;
  }

  // #region Classi per storico sessioni mobile
  export function map_ISession_to_ISession_FE_SessionHistory(session: ISession): ISession_FE_SessionHistory {
    const dest: ISession_FE_SessionHistory = {
      id: session.id,
      createdAt: session.createdAt,
      bookingEndAt: session.bookingEndAt,
      reservedAt: session.reservedAt,
      fullyChargedAt: session.fullyChargedAt,
      cancelledAt: session.cancelledAt,
      startedAt: session.startedAt,
      stoppedAt: session.stoppedAt,
      endedAt: session.endedAt,
      energy: session.energy,
      occupationTime: session.occupationTime,
      cpo: session.cpo,
      user: session.user ? map_IUser_to_IUser_FE_SessionHistory(session.user) : session.user,
      car: session.car,
      cdr: session.cdr ? map_IChargeDetailRecord_to_IChargeDetailRecord_FE_SessionHistory(session.cdr) : null,
      tariffJson: session.tariffJson,
      connector: session.connector ? map_IConnector_to_IConnector_FE_SessionHistory(session.connector) : null
    }
    return dest;
  }

  export function map_IChargeDetailRecord_to_IChargeDetailRecord_FE_SessionHistory(cdr: IChargeDetailRecord): IChargeDetailRecord_FE_SessionHistory {
    const dest: IChargeDetailRecord_FE_SessionHistory = {
      id: cdr.id,
      partyId: cdr.partyId,
      cdrToken: cdr.cdrToken,
      totalCost: cdr.totalCost,
      totalEnergy: cdr.totalEnergy,
      totalEnergyCost: cdr.totalEnergyCost,
      totalParkingCost: cdr.totalParkingCost,
      totalParkingTime: cdr.totalParkingTime,
      totalReservationCost: cdr.totalReservationCost,
      totalReservationTime: cdr.totalReservationTime,
      countryCode: cdr.countryCode,
      sessionId: cdr.sessionId,
      state: cdr.state,
      bankTransaction: cdr.bankTransaction ? map_IBankTransaction_to_IBankTransaction_FE_History(cdr.bankTransaction) : null,
      totalTime: cdr.totalTime,
      totalTimeCost: cdr.totalTimeCost
    }
    return dest;
  }

  export function map_IBankTransaction_to_IBankTransaction_FE_History(bank?: IBankTransaction): IBankTransaction_FE_History {
    const dest: IBankTransaction_FE_History = {
      id: bank?.id,
      createdAt: bank?.createdAt,
      sessionId: bank?.sessionId,
      type: bank?.type,
      value: bank?.value,
      state: bank?.state
    }
    return dest;
  }

  export function map_IUser_to_IUser_FE_SessionHistory(user?: IUser): IUser_FE_SessionHistory {
    const dest: IUser_FE_SessionHistory = {
      id: user?.id,
      name: user?.name,
      surname: user?.surname
    }
    return dest;
  }

  export function map_IConnector_to_IConnector_FE_SessionHistory(connector?: IConnector): IConnector_FE_SessionHistory {
    const dest: IConnector_FE_SessionHistory = {
      id: connector?.id,
      name: connector?.name,
      plugTypes: connector.plugTypes,
      power: connector.power,
      powerLimit: connector.powerLimit,
      station: connector?.station ? map_IStation_to_IStation_FE_SessionHistory(connector.station) : null
    }
    return dest;
  }

  export function map_IStation_to_IStation_FE_SessionHistory(station?: IStation): IStation_FE_SessionHistory {
    const dest: IStation_FE_SessionHistory = {
      id: station?.id,
      name: station?.name,
      cpo: station.cpo,
      installation: station?.installation ? map_IInstallation_to_IIstallation_FE_SessionHistory(station.installation) : null
    }
    return dest;
  }

  export function map_IInstallation_to_IIstallation_FE_SessionHistory(installation?: IInstallation): IInstallation_FE_SessionHistory {
    const dest: IInstallation_FE_SessionHistory = {
      id: installation?.id,
      name: installation?.name
    }
    return dest;
  }
  // #endregion

  // #region Classi per info utente mobile
  export function map_IUser_to_IUser_FE_Login(user: IUser): IUser_FE_Login {
    const dest: IUser_FE_Login = {
      id: user.id,
      name: user.name,
      surname: user.surname,
      email: user.email,
      wantsInvoice: user.wantsInvoice,
      addressNumber: user.addressNumber,
      addressStreet: user.addressStreet,
      postalCode: user.postalCode,
      city: user.city,
      province: user.province,
      country: user.country,
      countryId: user.countryId,
      pIva: user.pIva,
      pIvaUniCode: user.pIvaUniCode,
      taxCode: user.taxCode,
      userChapter: user.userChapter,
      userType: user.userType,
      cars: user.cars,
      visibilityGroups: user.visibilityGroups
    }
    return dest;
  }
  // #endregion
}