export { ActionTypeValues, ActionType } from './enums/actionType.enum'
export { AuthTypeValues, AuthType } from './enums/authType.enum'
export { AuthorizationMethodValues, AuthorizationMethod } from './enums/authorizationMethod.enum'
export { BankTransactionTypeValues, BankTransactionType } from './enums/bankTransactionType.enum'
export { BankTransactionStateValues, BankTransactionState } from './enums/bankTransactionState.enum'
export { ChapterTypeValues, ChapterType, ChapterTypeKeys } from './enums/chapterType.enum'
export { ChapterVisualizationPhaseValues, ChapterVisualizationPhaseKeys, ChapterVisualizationPhase } from './enums/chapterVisualizationPhase.enum'
export { CdrStateValues, CdrState } from './enums/cdrState.enum'
export { StatoCorrispettivoElettronicoValues, StatoCorrispettivoElettronicoKeys, StatoCorrispettivoElettronico } from './enums/statoCorrispettivoElettronico.enum';
export { CPOsValues, CPOsKeys, CPOs } from './enums/cpo.enum'
export { CurrencyValues, Currency } from './enums/currency.enum'
export { EnergyManagementValues, EnergyManagement } from './enums/energyManagement.enum'
export { ErrorCodeValues, ErrorCode } from './enums/errorCode.enum'
export { GroupTypeCategoryValues, GroupTypeCategoryKeys, GroupTypeCategory } from './enums/groupTypeCategory.enum'
export { GroupEntityTypeValues, GroupEntityTypeKeys, GroupEntityType } from './enums/groupEntityType.enum'
export { GestoreEntityTypeValues, GestoreEntityTypeKeys, GestoreEntityType } from './enums/gestoreEntityType.enum'
export { ILevelLogValues, ILevelLog } from './enums/levelLog.enum'
export { NotificationTypeValues, NotificationType } from './enums/notificationType.enum'
export { PlatformTypeValues, PlatformType } from './enums/platformType.enum'
export { PlugTypeValues, PlugType } from './enums/plugType.enum'
export { QuickFilterValues, QuickFilter } from './enums/quickFilter.enum'
export { RangeCardinalityValues, RangeCardinality } from './enums/ranegCardinality.enum'
export { ReasonTypeValues, ReasonType } from './enums/reasonType.enum'
export { RevenueDimensionValues, RevenueDimension } from './enums/revenueDimension.enum'
export { RevenueEntityValues, RevenueEntity, RevenueEntityKeys } from './enums/revenueEntity.enum'
export { RevenueSourceValues, RevenueSource } from './enums/revenueSource.enum'
export { RevenueStateValues, RevenueState } from './enums/revenueState.enum'
export { StateValues, State, getStateLabelByNumber } from './enums/state.enum'
export { SessionCdrStateValues, SessionCdrState } from './enums/sessionCdrState.enum'
export { SessionCmdValues, SessionCmd } from './enums/sessionCmd.enum'
export { SocialProviderValues, SocialProvider } from './enums/socialProvider.enum'
export { StationTypeValues, StationType } from './enums/stationType.enum'
export { TriggerMessageValues, TroubleshootingRequests, TroubleshootingRequestsValues, TriggerMessage, ResetTypeValues, ResetType } from './enums/troubleshootingRequests.enum'
export { UserTypeValues, UserType } from './enums/userType.enum';
export { GestoreUserRole, GestoreUserRoleValues, GestoreUserRoleKeys } from './enums/gestoreUserRole.enum';
export { SideMenuItems, SideMenuItemsValues, SideMenuContentItems, SideMenuContentItemsValues, SideMenuAboutItems, SideMenuAboutItemsValues, SideMenuReportsItems, SideMenuReportsItemsKeys, SideMenuReportsItemsValues } from './enums/sideMenuItems.enum';


export { IVersionUpdate } from "./app.interface";
export { ActionResponse, IAction } from "./action.interface";
export { IAuthState, IAuthToken, ISocialLoginDTO, ConfirmPasswordModel, IChangePasswordDTO, ILoginDTO, IOnlyEmailDTO, IRefreshTokenDTO, IRegisterDTO, LoginModel, SocialDecodedUser, ILoginSocialDTO, SignInResult } from "./auth.interface";
export { IBaseEntity, INumberIdEntity, IStringIdEntity } from "./base.interface";
export { ICar, ICarBrand, ICarFields, ICarModel, ICreateBrandDTO, ICreateCarDTO, ICreateModelDTO, IUpdateCarDTO } from "./car.interface"
export { IClient } from "./client.interface"
export { IConnector } from './connector.interface'
export { IMapBounds, IRange } from './map.interface'
export { OCPPComunication } from "./ocpp-gateway.interface"
export { ICreatePhotoDTO, IPhoto } from "./photo.interface"
export { ISession, ISessionCommand, ISessionResult, ISessionUserHistoryMonth, ISession_FE_SessionHistory } from "./session.interface"
export { IReview, ICreateReviewDTO } from "./review.interface"
export { IStation, IStationState, IUpdateStationDTO, CarFilters, IUpTime, IStation_GestoreUser } from "./station.interface"
export { ICreateUserDTO, IUpdateUserDTO, IUser, IUpdatePassword, UserSettings, IUserChapter, IUser_FE_Login, INewUser, IUserRolesAndItems, IUserLanguage, Language } from "./user.interface"
export { CU } from "./utils"
export { TariffUtil } from "./tariff-util"
export { ClientEvents, ServerEvents, WsAckResponse, WsResponse, WsSubRequest, IClientSocket, getNamespacesArray } from "./websockets/index"
export { WsNamespaces } from "./websockets/namespaces"
export { IError } from './errorCode.interface'
export { IMeterValue } from "./meter-value.interface"
export { IMeterTransfer, IMeterTransferPayload, ISampledValue } from "./meter-transfer.interface"
export { IStatusNotification } from "./status-notification.interface"
export { IStopTransaction } from "./stop-transaction.interface"
export { IPod, IUpdatePodDTO, ICreatePodDTO } from "./pod.interface"
export {
  IPaymentData,
  ICdrData,
  IPodData,
  IDashboardData,
  ISessionsData,
  IInstallationData,
  IStationsData,
  IUsersData,
  IClientsData,
  IAddressData,
  RecordCounted,
  ISearchCriteria,
  ISearchCriteriaGroupEntity,
  IGatewayData,
  ISearchCriteriaCDR,
  ISearchCriteriaUser,
  ISearchCriteriaStation,
  ISearchCriteriaInstallations,
  ISearchCriteriaGroups,
  ISearchCriteriaInvoice,
  ISearchCriteriaPayments,
  ISearchCriteriaSession,
  ISearchCriteriaWithState,
  ISideMenuItem, 
  IProceedsData,
} from "./utility.interface"
export {
  SelectSessionState,
  SelectSessionStateKeys,
  SelectSessionStateValues,
  SelectSessionTime,
  SelectSessionTimeKeys,
  SelectSessionTimeValues,
  SortingOptions,
  SortingOptionsKeys,
  SortingOptionsValues,
  SelectStationAndInstallationState,
  SelectStationAndInstallationStateKeys,
  SelectStationAndInstallationStateValues,
  SelectNumberOfConnector,
  SelectNumberOfConnectorKeys,
  SelectNumberOfConnectorValues,
  SelectCpo,
  SelectCpoKeys,
  SelectCpoValues,
  SelectOnlineStationState,
  SelectOnlineStationStateKeys,
  SelectOnlineStationStateValues,
  SelectCDRState,
  SelectCDRStateKeys,
  SelectCDRStateValues,
  SelectCorrispettivoState,
  SelectCorrispettivoStateKeys,
  SelectCorrispettivoStateValues,
  SelectPaymentState,
  SelectPaymentStateKeys,
  SelectPaymentStateValues,
  SortingOptionsShort,
  SortingOptionsShortKeys,
  SortingOptionsShortValues,
  SelectIsAdmin,
  SelectIsAdminKeys,
  SelectIsAdminValues,
  SelectGroupCategory,
  SelectGroupCategoryKeys,
  SelectGroupCategoryValues,
  SelectInvoiceState,
  SelectInvoiceStateKeys,
  SelectInvoiceStateValues
} from "./enums/select.enum"
export { IGroup, IType, ICreateGroupDTO, ISettings } from "./group.interface";
export { IGroupType, ICardinality, ICreateGroupTypeDTO } from "./group-type.interface"
export { IGroupEntity, IEntityType, ICreateGroupEntityDTO } from "./group-entity.inferface"
export { IInstallation, ICreateInstallationDTO, IUpdateInstallationDTO, IOpeningTimes, IDayPeriod, ITimePeriod, IInstallationState } from "./installation.interface"
export { IMunicipality, ICreateMunicipalityDTO, IUpdateMunicipalityDTO } from "./municipality.interface"
export { IProvince, ICreateProvinceDTO, IUpdateProvinceDTO } from "./province.interface"
export { IRegion, ICreateRegionDTO, IUpdateRegionDTO } from "./region.interface"
export { ICountry, ICreateCountryDTO, IUpdateCountryDTO } from "./country.interface"
export { ILog, IEntityLog } from './log.interface'
export { IRfid, ICreateRfidDTO, IUpdateRfidDTO } from "./rfid.interface"
export { INotification, IWPNotification, INAction } from "./notification.interface"
export { IBankTransaction, ICreateBankTransactionDTO, IUpdateBankTransactionDTO } from "./bank-transaction.interface"
export { IReason } from "./economic-management.interface"
export { OCPIGetLocation, OCPILocationRequest, OCPIReqParams, OCPIUnlockConectorParams, IEnergyContract, ProfileKeys, ProfileValues } from "./ocpi/ocpi.interface";
export { IToken } from "./ocpi/token.interface"
export { IPaymentAuth, ICreditCardDTO, IPaymentAuthRedirect } from "./payment-auth.interface";
export { IChargeDetailRecord, ICdrLocation, ISigned, ISignedData, ICorrispettivoElettronicoMetadata as ICorrispettivoElettronicoMetadata } from "./ocpi/cdr.interface";
export { COMMANDS, CommandKeys, CommandValues, OCPIReserveNowParams, COMMANDS_RESPONSE, CommandsResponseKeys, CommandsResultValues, ICommandResponse, COMMANDS_RESULT, CommandsResultKeys, CommandsResponseValues, WHITE_LIST, WhitelistKeys, WhitelistValues, PROFILE, ICommandResult, IUnlockConnectorBody } from "./ocpi/commands.interface";
export { OCPISession, ICdrToken, IChargingPeriod, IPrice, ISessionOCPICdrDimension, OCPICdrDimension, OCPIChargingPreferences } from "./ocpi/ocpi-session.interface";
export { ICostRevenueSplit, IRevenue, ITariff, ITariffLite, IRevenuesEntities, ITariffElements, ITariffRestrictions, IPriceComponent, ITariffRevenues, ITariffPlaceholders, ISimplePrices, ITariffTemplate, ITariffDescription, ITariffCalculateCostsInfo } from "./ocpi/tariff.interface";
export { ElementType, ValueOf } from "./ocpi/ocpi-types-helper";
export { IInfoSection, IInfoChapter, IMultimedia, IInfoContent, IInfoSectionVersion } from "./info.interface";
export { ITariffBO, ICreateTariffBODTO } from "./tariff-back-office.interface";
export { IGestore } from './gestore.interface';
export { IGestoreUser, ICreateGestoreUserDTO } from './gestore-user.interface';
export { IGestoreEntity } from './gestore-entity.interface'

export { OCPIBusinessDetails, OCPIRoles, OCPIBusinessLogo, ILocation, OCPIPublishTokenType, OCPIGeoLocation, OCPIAdditionalGeoLocation, OCPIEvse, OCPIDisplayText, OCPIImage, OCPIEnergyMix, OCPIPeriod, OCPIDayPeriod, IEnergySource, OCPIEnvironmentalImpact, STATUS_SCHEDULE, OCPIConnector, OCPICredential, OCPIEntityBase, OCPI_SUCCESS_STATUS } from "./ocpi/ocpi-interfaces";
export { ENERGY_SOURCE_CATEGORY, ENERGY_SOURCE_CATEGORYValues, ENERGY_SOURCE_CATEGORYKeys } from "./ocpi/enums/energySource.enum";
export { EVSE_STATUS, EVSE_STATUS_Values, EVSE_STATUS_Keys } from "./ocpi/enums/evseStatus.enum";
export { EVSE_CAPABILITY, EVSE_CAPABILITYValues } from "./ocpi/enums/evseCapability.enum";
export { IMAGE_CATEGORY, IMAGE_CATEGORYValues } from "./ocpi/enums/imageCategory.enum";
export { MODULE_ID, MODULE_IDValues } from "./ocpi/enums/moduleId.enum";
export { OCPIConnectorFormat, OCPIConnectorFormatValues } from "./ocpi/enums/ocpiConnectorFormat.enum";
export { OCPIConnectorType, OCPIConnectorTypeValues, OCPIConnectorTypeKeys } from "./ocpi/enums/ocpiConnectorType.enum";
export { OCPIFacilities, OCPIFacilitiesValues } from "./ocpi/enums/ocpiFacility.enum";
export { OCPIPowerType, OCPIPowerTypeValues } from "./ocpi/enums/ocpiPowerType.enum";
export { PARKING_RESTRICTIONS, PARKING_RESTRICTIONSValues } from "./ocpi/enums/parkingRestriction.enum";
export { PARKING_TYPES, PARKING_TYPESValues } from "./ocpi/enums/parkingType.enum";
export { ROLES, RolesValues } from "./ocpi/enums/roles.enum";
export { VOLTAGE, VOLTAGEValues } from "./ocpi/enums/voltage.enum";
export { TOKEN_TYPE, TokenValues, TokenKeys } from "./ocpi/enums/toknType.enum";
export { DAY_OF_WEEk, DayOfWeekValues, DayOFWeekKeys } from "./ocpi/enums/dayOfWeek.enum";
export { OCPIAuthMethod, OCPIAuthMethodValues, OCPIAuthMethodKeys } from "./ocpi/enums/ocpiAuthMethod.enum";
export { OCPISessionStatus, OCPISessionStatusValues, OCPISessionStatusKeys } from "./ocpi/enums/ocpiSessionStatus.enum";
export { OCPICdrDimensionType, OCPICdrDimensionTypeValues, OCPICdrDimensionTypeKeys } from "./ocpi/enums/ocpiCdrDimensionType.enum";
export { OCPIChargingPreferencesResponse, OCPIChargingPreferencesResponseValues, OCPIChargingPreferencesResponseKeys } from "./ocpi/enums/ocpiChargingPreferencesResponse.enum";
export { OCPIProfileType, OCPIProfileTypeValues, OCPIProfileTypeKeys } from "./ocpi/enums/ocpiProfileType.enum";
export { OCPITariffDimension, OCPITariffDImensionKeys, OCPITariffDimensionValues } from "./ocpi/enums/ocpiTariffDimension.enum";
export { OCPIReservationRestriction, OCPIReservationRestrictionValues, OCPIReservationRestrictionKeys } from "./ocpi/enums/ocpiReservationRestriction.enum";
export { OCPITariffType, OCPITariffTypeValues, OCPITariffTypeKeys } from "./ocpi/enums/ocpiTariffType.enum";
export { OCPIGatewayMessage, OCPIGatewayMessageValues, OCPIGatewayMessageKeys } from './ocpi/enums/ocpiGatewayMessages.enum';
export { IEntityTypeLog, IEntityTypeLogValues } from './enums/entityTypeLog.enum';
export { InvoiceState, InvoiceStateValues, InvoiceStateKeys } from './enums/invoiceState.enum';

export { SoapServiceUrl } from './enums/soapServiceUrl.enum';
export { IFatturaElettronica } from './templateFattura.interface';
export { IInvoice, DettaglioLinee, DatiRiepilogo } from './invoice.interface';

// Mapping
export { ISessionUserDTO } from './dtos/admin/sessionUserDto';
export { Mapper } from './mapping/mapper';

export { ID, BUILDERS, FILE_TYPES, IAgentState, IAgentQueueItem, IExportsArchiveMonth, IRequestCtx, Nullable, IGenericObj, IExcelReport, IExportsArchiveItem, IExportsArchiveYear, IExportsLink, IRequestExcelReportDTO } from './reports/excelReport'
export { IQuery, IExecutionQueryResult } from './reports/query';
export { ITemplate } from './reports/template';

