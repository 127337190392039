import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { IBaseEntity, IExecutionQueryResult, IGestore, IGroup, IQuery } from "common_library";
import { Observable, of } from "rxjs";
import { IExcelReport } from "common_library";
import { ConfigurationService } from "./configuration.service";
import { AppService } from "../app.service";
export interface IBaseEntityWithName extends IBaseEntity { name: string }

@Injectable({
  providedIn: 'root'
})
export class ConfiguratorHttpService<T extends IBaseEntityWithName> {
  backendUrl;
  constructor(
    private http: HttpClient,
    private nzns: NzNotificationService,
    private configService: ConfigurationService,
    private appService: AppService
  ) {
    this.backendUrl = this.appService.apiUrl;
    console.log("__BACKEND", this.backendUrl);

  }

  async patchEntity(url: string, body: Partial<T & { id: string }>): Promise<T> {
    return await this.http.patch<Partial<T>>(`${url}/${body.id}`, body)
      .toPromise()
      .then(entity => {
        this.nzns.success(`L'entità '${entity.name}' è stata aggiornata.`, '');
        return entity;
      }).catch(err => {
        this.nzns.error("L'entità NON è stata salvata", err.message);
        return null;
      });
  }

  async postEntity(url: string, body: Partial<T>): Promise<T> {
    return await this.http.post<Partial<T>>(url, body)
      .toPromise()
      .then(entity => {
        this.nzns.success(`L'entità '${entity.name}' è stata salvata.`, '');
        return entity;
      }).catch(err => {
        this.nzns.error("L'entità NON è stata salvata.", err.message);
        return null;
      });
  }

  async removeEntity(url: string, listElement: { name: string, id: string }) {
    return await this.http.delete(`${url}/${listElement.id}`)
      .toPromise()
      .then(removedId => {
        this.nzns.success(`L'entità '${listElement.name}' è stata rimossa.`, '');
        this.configService.clickListItem$.next(null);
        this.configService.currentActiveForm$.next(null);
        return removedId;
      }).catch(err => {
        this.nzns.error("L'entità NON è stata rimossa.", err.message);
        return null;
      });
  }

  async getStatesAndTaskTypes(url: string) {
    // Non erano any erano issueState e issueType, ma non li abbiamo
    return await this.http.get<{ issueStates: any[], issueTypes: any[] }>(`${url}/statesAndTypes`)
      .toPromise()
      .catch(err => {
        this.nzns.error('Can not get states and types.', err.message());
        return null;
      });
  }

  async getEntityById(url: string) {
    return await this.http.get<T>(`${url}`).toPromise()
  }

  async getAllEntities(url: string) {
    return await this.http.get<{ id: string, name: string }[]>(`${url}`).toPromise()
  }

  async getIssueStateByName(name: string) {
    return await this.http.get<{ id: string, name: string }[]>(`${this.backendUrl}/issueState/getByName?name=${name}`).toPromise();
  }

  getAllAvailableDomains(companyId: string) {
    return this.http.get<any[]>(`${this.backendUrl}/domain/getAllAvailables?companyId=${companyId}`);
  }

  getOloConfigs() {
    // return this.http.get<IOLOConfig[]>(`/api/OLOConfig`);
  }


  async getAllGlobalRoles() {
    // Non erano any erano IRole, ma non li abbiamo
    return await this.http.get<any[]>(`${this.backendUrl}/permissions/globalRoles`).toPromise();
  }

  async rawQuery(query: Partial<IQuery>, params: any): Promise<IExecutionQueryResult> {
    return await this.http.post<IExecutionQueryResult>(`${this.backendUrl}/query/exec`, { query, params }).toPromise();
  }

  allQuery$(): Observable<IQuery[]> {
    return this.http.get<IQuery[]>(`${this.backendUrl}/query`);
  }

  allGroups$(): Observable<IGroup[]> {
    return this.http.get<IGroup[]>(`${this.backendUrl}/group`);
  }

  allGestori$(): Observable<IGestore[]> {
    return this.http.get<IGestore[]>(`${this.backendUrl}/gestore`);
  }

  someQuery$(ids: string[]): Observable<IQuery[]> {
    return this.http.get<IQuery[]>(`${this.backendUrl}/query/some?ids=${ids.join(",")}`);
  }

  someGestori$(ids: string[]): Observable<IGestore[]> {
    if (typeof ids === 'undefined') {
      return of([]);
    }
    else {
      console.log("ID dei gestori: ", ids)
      return this.http.get<IGestore[]>(`${this.backendUrl}/gestore/some?ids=${ids.join(",")}`);
    }
  }

  templatesOfExport$(id: string): Observable<string[]> {
    return this.http.get<string[]>(`${this.backendUrl}/excelReport/templates/${id}`);
  }

  isQueryUsed$(id: string): Observable<IExcelReport[]> {
    return this.http.get<IExcelReport[]>(`${this.backendUrl}/excelReport/using-query/${id}`);
  }
}
