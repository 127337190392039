import { Injectable } from '@angular/core';
import { HttpIoService } from '../communication/http-io.service';
import { ClientEvents, IInstallation, ISearchCriteriaInstallations, RecordCounted, WsNamespaces } from 'common_library';
import { Point } from 'geojson';
import { CommonService } from '../common.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageService } from '../storage.service';
import { _ } from 'src/app/consts';
import { SocketIoService } from '../communication/socket-io.service';

const CONTROLLER_ROOT = 'installation';

@Injectable({ providedIn: 'root' })
export class InstallationService {
  lastSearchInstallationCount: number;

  private _mapSelectedInstallation: BehaviorSubject<IInstallation> = new BehaviorSubject<IInstallation>(null);
  public mapSelectedInstallation$ = this._mapSelectedInstallation.asObservable();

  private _mapInstallations: BehaviorSubject<IInstallation[]> = new BehaviorSubject<IInstallation[]>(null);
  public mapInstallations$ = this._mapInstallations.asObservable();

  constructor(private HIO: HttpIoService, private commonService: CommonService, private storage: StorageService, private SIS: SocketIoService) {

  }

  async update(installationId: string, dto: any): Promise<IInstallation> {
    try {
      return await this.HIO.patchItem<IInstallation, any>(`${CONTROLLER_ROOT}`, installationId, dto);
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async create(dto: any): Promise<IInstallation> {
    try {
      return await this.HIO.post<IInstallation, any>(`${CONTROLLER_ROOT}`, dto)
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async getInstallationById(id: string): Promise<IInstallation> {
    try {
      const rv = await this.HIO.get<IInstallation>(`${CONTROLLER_ROOT}/one/${id}`);
      return rv;
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  coordinateFormat(value) {
    let coordSplitted = value.split(",")
    const coordinates: Point = {
      type: 'Point',
      coordinates: [coordSplitted[0], coordSplitted[1]]
    }
    return coordinates
  }

  getAndCountInstallationsByStringPaginated(dto: ISearchCriteriaInstallations): Observable<RecordCounted<IInstallation>> {
    // const res = this.commonService.getAndCount$<IInstallation>({
    //   page: pageNumber,
    //   search: searchString,
    //   controllerRoot: CONTROLLER_ROOT,
    //   controllerDir: 'admin/filter-and-paginate'
    // });
    // return res;
    try {
      const route = `${CONTROLLER_ROOT}/admin/filter-and-paginate`
      const rv = this.HIO.post$<RecordCounted<IInstallation>, any>(route, dto)
      return rv;
    } catch (error) {
      console.log("🐸, error: ", error);
    }
  }

  async selectMapInstallation(id: string): Promise<void> {
    if (id) {
      const i = await this.getInstallationById(id);
      this.setSelectedInstallationStorage(id, i);
    }
    else {
      this.removeSelectedInstallation();
    }
    this.removeClusterStorage();
  }

  async checkLocalStorage() {
    const localClusterIds = this.storage.get<string[]>(_.MAP_CLUSTER_IDS);
    if (localClusterIds) await this.getInstallationsCluster(localClusterIds);
    const localInstallationId = this.storage.get<string>(_.MAP_SELECTED_INSTALLATION);
    if (localInstallationId) await this.selectMapInstallation(localInstallationId);
  }

  async getInstallationsCluster(ids: string[]): Promise<void> {
    const installations = await this.SIS.sendRequest<string[], IInstallation[]>(WsNamespaces.Client, ClientEvents.GetClusterByIds, ids);
    console.log('🐱️ : InstallationService -> getInstallationsCluster -> installations', installations);
    this.removeSelectedInstallation();
    this.setClusterStorage(ids, installations);
  }

  setSelectedInstallationStorage(id: string, installation?: IInstallation) {
    this.storage.set(_.MAP_SELECTED_INSTALLATION, id);
    if (installation) this._mapSelectedInstallation.next(installation);
  }

  removeSelectedInstallation() {
    this.storage.remove(_.MAP_SELECTED_INSTALLATION);
    this._mapSelectedInstallation.next(null);
  }

  setClusterStorage(ids: string[], installations?: IInstallation[]) {
    this.storage.set(_.MAP_CLUSTER_IDS, ids);
    if (installations) this._mapInstallations.next(installations);
  }

  removeClusterStorage() {
    this.storage.remove(_.MAP_CLUSTER_IDS);
    this._mapInstallations.next(null);
  }

}
