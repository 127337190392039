import { ChangeDetectorRef, Component, input, Input, OnInit, Output, output, signal } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
// import { AbstractDetail } from '../abstractDetail/abstractDetail.component';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
// import { ConfigurationService } from '../../../service/configurator/configuration.service';
// import { ConfiguratorHttpService } from '@ep-om/project/service/configurator/communication/configurator-http.service';
// import { ModalService } from '@ep-om/project/service/modal/modal.service';
import { BUILDERS, FILE_TYPES, IExcelReport, IGroup } from 'common_library';
import { sha1 } from 'object-hash';
import clone from 'lodash/clone';
// import { ExportsService } from ';
// import { Download } from '@ep-om/utils/download';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ExportsService } from 'src/app/services/reports/exports.service';
import { Download } from 'src/app/utils/reports/download';
import { ConfigurationService } from 'src/app/services/reports/configuration.service';
import { ConfiguratorHttpService } from 'src/app/services/reports/configHttp.service';
import { ModalService } from 'src/app/services/modal.service';
import { toSignal } from '@angular/core/rxjs-interop'
import { TranslateService } from '@ngx-translate/core';
import { DescriptionComponent } from '../../description/description.component';
import { AppService } from 'src/app/services/app.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-export-form',
  templateUrl: './export-form.component.html',
  styleUrls: ['./export-form.component.scss']
})
//Formly approach
export class ExportFormComponent {
  pageTitle = input('Export detail');
  blockHeaderTitle = input('Export detail');

  private readonly hideTypes = [{ value: 'fi-normal', label: this.translate.instant('REPORT.NO'), }, { value: 'fi-hide', label: this.translate.instant('REPORT.YES'), }];

  form = new UntypedFormGroup({});
  options: FormlyFormOptions = {};
  model: Partial<IExcelReport> = {}
  params: { [name: string]: number | string } = {};
  tester: {
    params: { form: UntypedFormGroup, options: FormlyFormOptions, model: { [name: string]: any }, fields: FormlyFieldConfig[] },
    format: { form: UntypedFormGroup, options: FormlyFormOptions, model: { [name: string]: any }, fields: FormlyFieldConfig[] },
  }

  @Input() elementListener$: BehaviorSubject<IExcelReport>;
  private _originalModel: IExcelReport = null;
  private _queriesParamsChanged$: Observable<string> = this.form.valueChanges.pipe(

    map(f => f.queries), // mi interessa solo la lista degli delle query
    filter(qs => !!this.form.valid), // solo quando la form è vaida così evito chiamate inutili durante la compilazione
    distinctUntilChanged((x, y) => sha1(x) === sha1(y)),
    tap(q => this._loading$.next(true)),
    switchMap(queries => this.configHttpService.someQuery$(queries)), // chiedo al BE le query intere (perchè mi servono i parametri)
    tap(q => this._loading$.next(false)),
    map(queries => queries.map(q => q.params)), // appunto mi servono i parametri
    map(params => { // in realtà mi serve la DISTINC dei paramentri. params è un array di array, devo appiattirlo e poi fare la distinct per avere la lista dei paramentri distinti
      const flattenedArray: string[] = [].concat(...params);
      const uniqueArray: string[] = Array.from(new Set(flattenedArray));
      return uniqueArray.join(",");


    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  private _managersParamsChanged$: Observable<string> = this.form.valueChanges.pipe(

    map(f => f.managers), // Mi interessa solo la lista degli ID dei gestori
    filter(ms => !!this.form.valid && !!ms && ms.length > 0), // Solo quando la form è valida e "gestori" ha un valore
    distinctUntilChanged((x, y) => sha1(x) === sha1(y)),
    tap(m => this._loading$.next(true)),
    switchMap(managers => this.configHttpService.someGestori$(managers)), // Chiedo al BE gli ID completi dei gestori
    tap(m => this._loading$.next(false)),
    map(managers => managers.map(m => m.id)),
    map(id => {
      const flattenedArray: string[] = [].concat(...id);
      const uniqueArray: string[] = Array.from(new Set(flattenedArray));
      return uniqueArray.join(",");
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );



  private _paramsChanged$: Observable<string> = this.form.valueChanges.pipe(map(f => (this.model.formFields?.map(f => f.key) || []).join(",")), filter(qs => !!this.form.valid), distinctUntilChanged());

  private _check$ = combineLatest([this._queriesParamsChanged$, this._managersParamsChanged$, this._paramsChanged$]).pipe(
    filter(qs => !this._loading$.value), // eventuali emissioni durante il caricamento dal BE le evito
    map(([requiredParams, currentParams]) => {
      const unnecessary = currentParams.split(",").filter(item => !requiredParams.split(",").includes(item) && item !== "");
      const missings = requiredParams.split(",").filter(item => !currentParams.split(",").includes(item) && item !== "");
      return { unnecessary, missings };
    }));

  public checkOk$ = this._check$.pipe(map(rv => rv.unnecessary.length == 0 && rv.missings.length == 0));
  public err$ = this._check$.pipe(
    map(rv => {
      console.log(rv);
      if (rv.unnecessary.length == 0 && rv.missings.length == 0) return null;
      let error = rv.unnecessary.length > 0 ? `Parametri superflui: ${rv.unnecessary.join(",")}` : "";
      error += rv.missings.length > 0 ? ` Parametri mancanti: ${rv.missings.join(",")}` : "";
      return error.trim();
    }),
  );

  public download$: Observable<Download>;

  private _excelReport$ = new Subject<IExcelReport>(); 
  excelReport$ = this._excelReport$.asObservable();

  private _loading$ = new BehaviorSubject<boolean>(false); 
  loading$ = this._loading$.asObservable();
  //private _checkOk$ = new BehaviorSubject<boolean>(false); checkOk$ = this._checkOk$.asObservable();
  public _testerOpen$ = new BehaviorSubject<boolean>(false); testerOpen$ = this._testerOpen$.asObservable();
  private _allQuery$ = this.configHttpService.allQuery$().pipe(map(items => items.map(i => { return { value: i.id, label: i.name } })));
  //private _allGroups$ = this.configHttpService.allGroups$().pipe(map(items => items.map(i => { return { value: i.id, label: i.name } })));

  private _allGestori$ = this.configHttpService.allGestori$().pipe(map(items => items.map(i => { return { value: i.id, label: i.businessName } })));


  fileTypes$ = of(FILE_TYPES).pipe(map(arr => { return arr.map(i => { return { value: i, label: i.toUpperCase() } }) }));
  builders$ = of(BUILDERS).pipe(map(arr => { return arr.map(i => { return { value: i, label: i.toUpperCase() } }) }));

  private _templatesChanged$ = new BehaviorSubject<void>(null);
  templatesChanged$ = this._templatesChanged$.asObservable();
  templates$: Observable<string[]> = combineLatest([this.templatesChanged$, this.excelReport$]).pipe(
    map(([_, er]) => er.id), // mi interessa solo l'Id
    switchMap(id => !!id ? this.configHttpService.templatesOfExport$(id) : of([])), // chiedo al BE i templates di questo elemento
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  templatesForField$ = this.templates$.pipe(
    tap((a)=>console.log("templatesForField$",a)),
    map(arr => { arr.push(null); return arr; }),// aggiungo "null" ovvero nessun template
    map(arr => { return arr.map(i => { return { value: i, label: i?.toUpperCase() } }) }));

  public downloadTemplate$: Observable<Download>;

  fileList: NzUploadFile[] = [];

  sub: Subscription;
  reportId: string;
  export: IExcelReport;
  fields: FormlyFieldConfig[] = this.getUpdatedFields();
  formatFields: FormlyFieldConfig[] = this.getUpdatedFormatFields();
  private langChangeSubscription: Subscription;

  constructor(
    protected configService: ConfigurationService,
    protected configHttpService: ConfiguratorHttpService<IExcelReport>,
    protected modalService: ModalService,
    private exportsService: ExportsService,
    private nzns: NzNotificationService,
    protected _cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private app: AppService,
    private router: Router,
    private AC: ActivatedRoute,
    private appService: AppService
  ) {

    this.testerOpen$.subscribe(open => {
      if (open) {
        console.log("APRO IL TESTER");

        this.tester = {
          params: null,
          format: {
            form: new UntypedFormGroup({}),
            options: {},
            model: clone({ file: this.model.file, builder: this.model.builder, template: this.model.template }),
            fields: this.formatFields,
          }
        }

        if (!!this.model.formFields && this.model.formFields.length > 0) {
          this.tester.params = {
            form: new UntypedFormGroup({}),
            options: {},
            model: {},
            fields: clone<FormlyFieldConfig[]>(this.model.formFields)
          }
        }

        this.download$ = of(null);

      } else {
        console.log("CHIUDO IL TESTER");

        if (!!this.tester) {
          if (this.tester.format.model.file != this.model.file || this.tester.format.model.builder != this.model.builder || this.tester.format.model.template != this.model.template) {
            console.log(this.tester.format.model);
            this.form.patchValue({ file: this.tester.format.model.file, builder: this.tester.format.model.builder, template: this.tester.format.model.template });
            this.form.markAsDirty();
          }
          this.tester = null;
        }

      }
    });

    this.sub = this.AC.paramMap.subscribe(async (params) => {
      this.reportId = params.get('reportId');
      this.export = await this.exportsService.getReport(this.reportId);
      this._excelReport$.next(this.export);
      if (this.export == null) {
        this.model.name = '';
        this.model.description = '';
        this.model.template = '';
        this.model.icon = '';
        this.model.category = '';
        //this.model.file = '';
        // this.model.builder = '';
        this.model.formFields = [];
        this.model.home = false;
        this.model.gestore = [];
        this.model.queries = [];
        this.model.id = '';
      } else {
        this.model = this.export; // Imposta il modello per il form
        this.form.patchValue(this.model); // Popola il form
      }
    });

    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this.fields = this.getUpdatedFields();
      this.formatFields = this.getUpdatedFormatFields();
    });

  }

  // home: boolean;
  // prjs: string[];
  // queries: string[];

  async ngOnInit() {
    this.sub = this.AC.paramMap.subscribe(async (params) => {
      this.reportId = params.get('reportId');
      this.export = await this.exportsService.getReport(this.reportId);

      if (this.export == null) {
        this.model.name = '';
        this.model.description = '';
        this.model.template = '';
        this.model.icon = '';
        this.model.category = '';
        //this.model.file = '';
        //this.model.builder = '';
        this.model.formFields = [];
        this.model.home = false;
        this.model.gestore = [];
        this.model.queries = [];
        this.model.id = '';
      } else {
        this.model = this.export; // Imposta il modello per il form
        this.form.patchValue(this.model); // Popola il form
        this.form.markAsDirty(); // Per evitare che il form venga considerato "pristine" e quindi non venga chiesto di salvare
      }
    });
  }

  ngOnDestroy() {
    this.langChangeSubscription.unsubscribe();
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
  getUpdatedFormatFields(): FormlyFieldConfig[] {
    return [{
      fieldGroup: [
        {
          key: 'file',
          type: 'select',
          className: "fi-xsmall",
          props: {
            label: this.translate.instant('REPORT.FILE'),
            placeholder: this.translate.instant('REPORT.SELECT_TYPE'),
            required: true,
            multiple: false,
            options: this.fileTypes$
          }
        },
        {
          key: 'builder',
          type: 'select',
          className: "fi-xsmall",
          props: {
            label: this.translate.instant('REPORT.BUILDER'),
            placeholder: this.translate.instant('REPORT.SELECT_BUILDER'),
            required: false,
            multiple: false,
            options: this.builders$,
          },
          expressions: {
            'props.disabled': "model.file === 'pdf' || !model.file",
            'model.builder': "model.file === 'pdf' ? 'excel-js' : model.builder"
          }
        },
        {
          key: 'template',
          type: 'select',
          className: "fi-xlarge",
          props: {
            label: this.translate.instant('REPORT.TEMPLATE'),
            placeholder: this.translate.instant('REPORT.SELECT_TEMPLATE'),
            required: false,
            multiple: false,
            options: this.templatesForField$
          },
          expressions: {
            'props.disabled': "model.builder !== 'xlsx-template' || !model.builder",
            'model.template': "model.builder !== 'xlsx-template' ? model.builder : null"
          }
        }
      ],
      fieldGroupClassName: "fg"
    }];
  }

  getUpdatedFields(): FormlyFieldConfig[] {
    return [
      {
        fieldGroup: [
          {
            key: "name",
            type: "input",
            className: "fi-small",
            props: {
              type: "text",
              label: this.translate.instant("REPORT.NAME"),
              required: true
            }
          },
          {
            key: 'description',
            type: 'input',
            className: "fi-normal",
            props: {
              label: this.translate.instant('REPORT.DESCRIPTION'),
              required: true,
              type: "text"
            }
          }
        ], fieldGroupClassName: "fg"
      },
      {
        fieldGroup: [
          {
            key: "category",
            type: "input",
            className: "fi-small",
            props: {
              type: "text",
              label: this.translate.instant('REPORT.CATEGORY'),
              required: true
            }
          },
          {
            key: "icon",
            type: "formly-ant-icon-select",
            className: "fi-xsmall",
            props: {
              label: this.translate.instant('REPORT.ICON'),
              required: true
            }
          },
        ], fieldGroupClassName: "fg"
      },
      { template: `<div class='form-section-header'>${this.translate.instant('REPORT.FORMAT')}</div>` },
      {
        fieldGroup: [
          {
            key: 'file',
            type: 'select',
            className: "fi-xsmall",
            props: {
              label: 'File',
              placeholder: this.translate.instant('REPORT.SELECT_TYPE'),
              required: true,
              multiple: false,
              options: this.fileTypes$
            },
          },
          {
            key: 'builder',
            type: 'select',
            className: "fi-xsmall",
            props: {
              label: 'Builder',
              placeholder: this.translate.instant('REPORT.SELECT_BUILDER'),
              required: false,
              multiple: false,
              options: this.builders$,
            },
            expressions: {
              'props.disabled': "model.file === 'pdf' || !model.file",
              'model.builder': "model.file === 'pdf' ? 'excel-js' : model.builder"
            }
          },
          {
            key: 'template',
            type: 'select',
            className: "fi-xlarge",
            props: {
              label: this.translate.instant('REPORT.TEMPLATE'),
              placeholder: this.translate.instant('REPORT.SELECT_TEMPLATE'),
              required: false,
              multiple: false,
              options: this.templatesForField$
            },
            expressions: {
              'props.disabled': "model.builder !== 'xlsx-template' || !model.builder",
            }
          }
        ], fieldGroupClassName: "fg"
      },
      { template: `<div class='form-section-header'>${this.translate.instant('REPORT.VISIBILITY')}</div>` },
      {
        "fieldGroup": [
          // { "key": "home", "type": "switch", "className": "fi-xsmall", "props": { "label": "In Home" } },
          {
            key: 'gestore',
            type: 'select',
            className: "fi-xlarge",
            props: {
              label: this.translate.instant('REPORT.MANAGERS'),
              placeholder: this.translate.instant('REPORT.SELECT_MANAGERS'),
              required: false, // Lascio in caso si cambi idea 
              multiple: true,
              options: this._allGestori$
            },
          }
        ], fieldGroupClassName: "fg"
      },
      // { "fieldGroup": [
      //   { "key": 'prjs', "type": 'select', "className": "fi-xlarge", "props": { "label": 'Progetti', placeholder: 'seleziona uno o più progetti', description: 'Description', required: false, multiple:true, options: this._allProjects$ } } ], "fieldGroupClassName": "fg" },
      { template: `<div class='form-section-header'>${this.translate.instant('REPORT.DATA')}</div>` },
      {
        fieldGroup: [{
          key: 'queries',
          type: 'select',
          className: "fi-normal",
          props: {
            label: this.translate.instant('REPORT.QUERY'),
            placeholder: this.translate.instant('REPORT.SELECT_QUERY'),
            required: true,
            multiple: true,
            options: this._allQuery$
          }
        }], fieldGroupClassName: "fg"
      },
      {
        fieldGroup: [{
          key: 'formFields',
          type: 'repeat-section',
          className: "fi-normal",
          props: {
            label: this.translate.instant('REPORT.PARAMETERS'),
          },
          fieldArray: {
            fieldGroupClassName: 'fg',
            fieldGroup: [
              {
                key: 'type',
                defaultValue: 'input'
              },
              {
                key: 'props.required',
                defaultValue: true
              },
              {
                key: 'key',
                type: 'input',
                className: 'fi-normal',
                props: {
                  label: this.translate.instant('REPORT.NAME'),
                  required: true,
                }
              },
              {
                key: 'props.label',
                type: 'input',
                className: 'fi-normal',
                props: {
                  label: this.translate.instant('REPORT.LABEL'),
                  required: true,
                }
              },
              {
                key: 'props.type',
                type: 'select',
                className: "fi-normal",
                props: {
                  label: this.translate.instant('REPORT.TYPE'),
                  required: true,
                  //options: this.fieldTypes
                  options: [
                    {
                      value: 'ctx',
                      label: this.translate.instant('REPORT.CONTEXT_CTX'),
                    },
                    {
                      value: 'constant',
                      label: this.translate.instant('REPORT.CONSTANT'),
                    },
                    {
                      value: 'text',
                      label: this.translate.instant('REPORT.TEXT'),
                    },
                    {
                      value: 'number',
                      label: this.translate.instant('REPORT.NUMERIC'),
                    },
                    {
                      value: 'date',
                      label: this.translate.instant('REPORT.DATE'),
                    },
                    {
                      value: 'month',
                      label: this.translate.instant('REPORT.MONTH')
                    }
                  ],
                }
              },
              {
                key: 'defaultValue',
                type: 'input',
                className: 'fi-normal',
                props: {
                  label: this.translate.instant('REPORT.CONSTANT_VALUE'),
                  required: false
                }
              },
            ]
          }
        }], fieldGroupClassName: "fg"
      },
    ];
  }

  protected makeForm(item: IExcelReport): void {
    //console.log("makeForm",item);
    this._excelReport$.next(item);

    // CLEAR
    this.downloadTemplate$ = of(null);
    this._originalModel = clone<IExcelReport>(item);

  }

  async submitForm() {
    if (this.form.valid) {
      const exportDto = {
        name: this.model.name,
        description: this.model.description,
        template: this.model.template,
        icon: this.model.icon,
        category: this.model.category,
        file: this.model.file,
        builder: this.model.builder,
        formFields: this.model.formFields,
        home: this.model.home,
        gestore: this.model.gestore,
        queries: this.model.queries,
        id: this.reportId,
      };
      try {
        if (this.reportId !== null) {
          const rv: IExcelReport = await this.exportsService.createOrUpdateReportByForm(exportDto);
          this.router.navigate([`/authenticated/reports-export/detail/${rv.id}`]);
          this.app.createNotification('success', this.translate.instant("PHRASE.UPDATE_DONE"), this.translate.instant("PHRASE.UPDATE_REPORT"))
        } else {
          const rv = await this.exportsService.createOrUpdateReportByForm(exportDto);
          this.router.navigate([`/authenticated/reports-export/detail/${rv.id}`]);
          this.app.createNotification('success', this.translate.instant("PHRASE.CREATION_DONE"), this.translate.instant("PHRASE.CREATE_REPORT"))
        }
      } catch (error) {
        this.app.createNotification('error', this.translate.instant("PHRASE.SOMETHING_WENT_WRONG"), this.translate.instant("PHRASE.ERROR_TRY_AGAIN"))
      }
    }
  }

  reset() {
    this.options.resetModel();
  }

  async doExport() {

    const rv = this.exportsService.createExport({ model: this.tester.params?.model, excelReportId: this.model.id, file: this.tester.format.model.file, builder: this.tester.format.model.builder, template: this.tester.format.model.template, skipArchiving: true }, this.model.name);

    this.download$ = rv.pipe(catchError((error, c) => {
      return of(error);
    }));

  }

  async createXlsxTemplateSample() {
    const rv = this.exportsService.createXlsxTemplateSample({ model: this.tester.params?.model, excelReportId: this.model.id, }, this.model.name + " - TEMPLATE DEMO");

    this.download$ = rv.pipe(catchError((error, c) => {
      console.log(error);
      return of(error);
    }));
  }

  handleUploadEvents(evt: NzUploadChangeParam): void {

    if (evt.file.status !== 'uploading') {
      console.log(evt.file, evt.fileList);
    }
    if (evt.file.status === 'done') {
      this._templatesChanged$.next(); 
      console.log(`${evt.file.name} file uploaded successfully`);
    } else if (evt.file.status === 'error') {
      console.warn(`${evt.file.name} file upload failed.`);
    }
  }

  uploadUrl(): string {
    return `${this.appService.apiUrl}/excelReport/upload-template/${this.model.id}`; 
  }

  async removeTemplate(e: Event, template: string) {

    e.preventDefault();
    e.stopPropagation();

    if ([this.model.template?.toLowerCase(), this._originalModel?.template].includes(template.toLowerCase())) {
      this.nzns.warning(this.translate.instant("NZMESSAGE.ATTENTION"), this.translate.instant("REPORT.TEMPLATE_IN_USE"));
      return;
    }

    // controllo se il tempalte non è quello utilizzato ... ed eventualmente blocco
    const ok = await this.modalService.showGenericConfirm(this.translate.instant("REPORT.CONFIRM_TEMPLATE_DELETION_IT", { template: template }), this.translate.instant("REPORT.DELETE"), true, this.translate.instant("REPORT.WARNING_IRREVERSIBLE_OPERATION"));
    if (ok) {
      const rv = await this.exportsService.removeTemplate(this.model.id, template);
      if (!rv) {
        this.nzns.warning(this.translate.instant("REPORT.DELETE_TEMPLATE"), this.translate.instant("REPORT.WARNING_TEMPLATE_NOT_DELETED_IT", { template: template }));
      } else {
        this.nzns.success(this.translate.instant("REPORT.DELETE_TEMPLATE"), this.translate.instant("REPORT.SUCCESS_TEMPLATE_DELETED_IT", { template: template }));
      }
      this._templatesChanged$.next();
    }
  }

  async downloadTemplate(template: string) {
    this.downloadTemplate$ = await this.exportsService.downloadTemplate(this.model.id, template);
  }

  get testerTableHeight(): string {
    return (window.innerHeight - 440) + 'px';
  }

}
