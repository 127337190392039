import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CU, ISearchCriteriaSession, ISession, RecordCounted, SelectSessionState, SelectSessionStateValues, SelectSessionTime, SelectSessionTimeValues, SortingOptions, SortingOptionsShort, SortingOptionsShortValues } from 'common_library';
import { BehaviorSubject, combineLatest, debounceTime, map, Observable, startWith, Subscription, switchMap } from 'rxjs';
import { SessionService } from 'src/app/services/entities/session.service';
import { StorageService } from '../../services/storage.service';
import { AppService } from 'src/app/services/app.service';
import { _ } from 'src/app/consts';
import { TranslateService } from '@ngx-translate/core';
import { getSessionState, getSessionTimeInterval, getSortingOptionsShort } from 'src/app/types/types_filter';

const CONTROLLER_ROOT = 'session';
export const LOCAL_STORAGE_SESSIONS = 'select_sessions_state';
export const LOCAL_STORAGE_DATE_RANGE = 'select_date_range';

const FILTER_INITIAL_STATE: ISearchCriteriaSession = {
  sessionState: SelectSessionState.ACTIVE,
  sessionTimeSelect: SelectSessionTime.TODAY,
  dateRange: CU.dateCalcToArrayOfDates(SelectSessionTime.TODAY),
  pageIndex: 1,
  searchString: '',
  sorting: SortingOptionsShort.NEW
}

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class SessionsComponent implements OnInit {

  sessions: ISession[];
  utilityFuncs = CU;
  sessionFormState$ = new BehaviorSubject<SelectSessionStateValues>(SelectSessionState.ACTIVE);
  sessionFormTime$ = new BehaviorSubject<SelectSessionTimeValues>(SelectSessionTime.TODAY);
  SESSION_TIME_SELECT = SelectSessionTime;
  customRangeValue: any = [CU.dateCalcToArrayOfDates(SelectSessionTime.TODAY).startTime, CU.dateCalcToArrayOfDates(SelectSessionTime.TODAY).endTime];
  customDateRange$ = new BehaviorSubject<{
    startTime: Date,
    endTime: Date
  }>(CU.dateCalcToArrayOfDates(SelectSessionTime.TODAY));

  sessionDateRange$ = combineLatest([
    this.sessionFormTime$,
    this.customDateRange$
  ]).pipe(
    map(([sessionTime, customDateRange]) => {
      if (sessionTime === SelectSessionTime.CUSTOM_RANGE) return customDateRange;
      else return CU.dateCalcToArrayOfDates(sessionTime);
    }))

  sortingFormState$ = new BehaviorSubject<SortingOptionsShortValues>(SortingOptionsShort.NEW);
  currentIndex = 1;

  pageIndex$ = new BehaviorSubject<number>(1);
  searchText$ = new BehaviorSubject<string>('');
  filterCriteria$ = combineLatest([
    this.searchText$.pipe(startWith((this.storage.get(_.USERS_SEARCH_CRITERIA) as ISearchCriteriaSession)?.searchString), debounceTime(500)),
    this.pageIndex$.pipe(startWith((this.storage.get(_.USERS_SEARCH_CRITERIA) as ISearchCriteriaSession)?.pageIndex)),
    this.sessionFormState$.pipe(startWith(SelectSessionState.ACTIVE)),
    this.sessionFormTime$.pipe(startWith(SelectSessionTime.TODAY)),
    this.sortingFormState$.pipe(startWith(SortingOptionsShort.NEW)),
    this.sessionDateRange$,
  ]).pipe(
    debounceTime(250),
    map(
      ([searchText, pageIndex, sessionState, sessionTime, sortingState, dateRange]) => {
        return {
          searchText: searchText,
          pageIndex,
          sessionState,
          dateRange,
          sessionTimeSelect: sessionTime,
          sorting: sortingState
        }
      }
    ));
  sessions$: Observable<RecordCounted<ISession>>;


  _showSortingOptions: boolean = false;
  _showFilters: boolean = false;
  _isFilterActive: boolean = false;
  formSorting = new UntypedFormGroup({});
  fieldsSorting: FormlyFieldConfig[] = this.getUpdatedFieldsSorting();
  formFilters = new UntypedFormGroup({});
  fieldsFilters: FormlyFieldConfig[] = this.getUpdatedFieldsFilters();
  subscriptions: Subscription[] = [];

  private langChangeSubscription: Subscription;

  constructor(
    private sessionService: SessionService,
    private storage: StorageService,
    public app: AppService,
    private translate: TranslateService
  ) {
    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this.fieldsFilters = this.getUpdatedFieldsFilters();
      this.fieldsSorting = this.getUpdatedFieldsSorting()
    });
  }

  ngOnInit(): void {
    this.setUpInitialFormValues();
    this._isFilterActive = this.isFilterDifferentFromDefault();

    this.subscriptions.push(
      this.formSorting.valueChanges.subscribe((value) => {
        if (value.sorting !== this.sortingFormState$.getValue() && typeof value.sorting !== 'undefined') {
          this.pageIndex$.next(1);
        }
        if (!!value.sorting) this.sortingFormState$.next(value.sorting);
      })
    );

    this.subscriptions.push(
      this.formFilters.valueChanges.subscribe((value) => {
        if (value.state !== this.sessionFormState$.getValue() && typeof value.state !== 'undefined') {
          this.pageIndex$.next(1);
        }
        if (!!value.state) {
          this.sessionFormState$.next(value.state);
          if (value.state === SelectSessionState.ACTIVE) this.sessionFormTime$.next(SelectSessionTime.TODAY)
        }


        if (value.time !== this.sessionFormTime$.getValue() && typeof value.time !== 'undefined') {
          this.pageIndex$.next(1);
        }
        if (!!value.time) this.sessionFormTime$.next(value.time);
      }));

    // this.subscriptions.push(
    //   this.formFilters.valueChanges.subscribe((value) => {
    //     if(!!value.datePicker) this.customDateRange$ = value.datePicker;
    //   }
    // ))

    this.filterCriteria$.subscribe((v) => {
      this.storage.set(_.SESSION_SEARCH_CRITERIA, v);
      this._isFilterActive = this.isFilterDifferentFromDefault();
    })

    this.sessions$ = this.filterCriteria$.pipe(
      switchMap((searchCriteria) => {
        return this.sessionService.getSessionsByDate$({
          pageIndex: searchCriteria.pageIndex,
          searchString: searchCriteria.searchText,
          sorting: searchCriteria.sorting,
          sessionState: searchCriteria.sessionState,
          sessionTimeSelect: searchCriteria.sessionTimeSelect,
          dateRange: searchCriteria.dateRange,
        });
      })
    );

    this.customDateRange$.subscribe({
      next: (value) => {
        this.customRangeValue = [value.startTime, value.endTime];
      }
    })
  }


  setUpInitialFormValues() {
    // Qui metteremo la logica per inizializzare il componento
    let localStorageSession: ISearchCriteriaSession = this.storage.get(_.SESSION_SEARCH_CRITERIA);
    if (!localStorageSession) localStorageSession = FILTER_INITIAL_STATE;
    // Sincronizza il valore iniziale del BehaviorSubject
    this.nextFilterStates(localStorageSession);
  }

  nextFilterStates(localStorageSession: ISearchCriteriaSession) {
    this.searchText$.next(localStorageSession.searchString);
    this.pageIndex$.next(localStorageSession.pageIndex);
    this.sortingFormState$.next(localStorageSession.sorting);
    this.sessionFormState$.next(localStorageSession.sessionState);
    this.sessionFormTime$.next(localStorageSession.sessionTimeSelect);
    this.customDateRange$.next(localStorageSession.dateRange);
  }

  isFilterDifferentFromDefault(): boolean {
    const storedCriteria = JSON.parse(localStorage.getItem(_.SESSION_SEARCH_CRITERIA));
    if (!storedCriteria) return false;
    let isDefault: boolean =
      storedCriteria.sessionState !== SelectSessionState.ACTIVE ||
      storedCriteria.sessionTimeSelect !== SelectSessionTime.TODAY;

    if (isDefault) this._showFilters = true;
    return isDefault;
  }

  getUpdatedFieldsSorting(): FormlyFieldConfig[] {
    return [
      {
        fieldGroup: [
          {
            className: "fi-column",
            key: 'sorting',
            type: 'select',
            props: {
              label: this.translate.instant("FILTERS_OPTIONS.SORT_BY"),
              valueProp: 'value',
              labelProp: 'label',
              options: getSortingOptionsShort(this.translate)
            },
            hooks: {
              onInit: (form) => {
                form.formControl.setValue(this.sortingFormState$.getValue());
              }
            }
          }
        ], fieldGroupClassName: "fg-wrap"
      }
    ];
  }


  getUpdatedFieldsFilters(): FormlyFieldConfig[] {
    return [
      {
        fieldGroup: [
          {
            className: "fi-column",
            key: 'state',
            type: 'select',
            props: {
              label: this.translate.instant("FILTERS_OPTIONS.STATE"),
              valueProp: 'value',
              labelProp: 'label',
              options: getSessionState(this.translate)
            },
            hooks: {
              onInit: (form) => {
                form.formControl.setValue(this.sessionFormState$.getValue());
              }
            }
          },
          {
            className: "fi-column",
            key: 'time',
            type: 'select',
            expressions: {
              hide: (form): boolean => {
                if (this.sessionFormState$?.getValue() === SelectSessionState.ACTIVE) {
                  return true;
                } else {
                  form.formControl.setValue(this.sessionFormTime$.getValue());
                  return false;
                }
              }
            },
            props: {
              label: this.translate.instant("FILTERS_OPTIONS.WHEN"),
              valueProp: 'value',
              labelProp: 'label',
              options: getSessionTimeInterval(this.translate)
            },
            hooks: {
              onInit: (form) => {
                form.formControl.setValue(this.sessionFormTime$.getValue());
              }
            }
          }
        ], fieldGroupClassName: "fg-wrap"
      }
    ];
  }

  ngOnDestroy(): void {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private isTooMuchDays(days: number): boolean {
    return days > 31 ? true : false;
  }

  async onEventByDateRange(range: Date[]) {
    if (!range || range.length < 2) return;
    const startTimecustomRange = new Date(range[0].getFullYear(), range[0].getMonth(), range[0].getDate(), 0, 0, 0);
    const endTimecustomRange = new Date(range[1].getFullYear(), range[1].getMonth(), range[1].getDate(), 23, 59, 59)
    const dateRange = {
      startTime: startTimecustomRange,
      endTime: endTimecustomRange
    }
    const getDaysDifference = (date1: Date, date2: Date): number => {
      const diffTime = Math.abs(date2.getTime() - date1.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    }

    const totalDays = getDaysDifference(dateRange.startTime, dateRange.endTime);
    if (this.isTooMuchDays(totalDays)) {
      this.app.createNotification('error', this.translate.instant("PHRASE.ERROR"), this.translate.instant("FILTERS_OPTIONS.SEARCH_PERIOD_TOO_LONG"))
    }
    else {
      this.customDateRange$.next(dateRange);
    }
  }

  async refreshActiveSessions() {
    this.searchText$.next(this.searchText$.getValue());
  }

  pageIndexChange(page) {
    this.searchText$.next(this.searchText$.getValue()),
      this.pageIndex$.next(page);
  }

  getDates(): Date[] {
    const dateRangeFromStorage = (this.storage.get(_.SESSION_SEARCH_CRITERIA) as ISearchCriteriaSession)?.dateRange;
    const customDateRange = this.customDateRange$.getValue();
    return !!dateRangeFromStorage ? [dateRangeFromStorage.startTime, dateRangeFromStorage.endTime] : [customDateRange.startTime, customDateRange.endTime];
  }


  showSortingOptions() {
    this._showSortingOptions = !this._showSortingOptions
    if (this._showSortingOptions) this._showFilters = false;
  }

  showFilters() {
    this._showFilters = !this._showFilters
    if (this._showFilters) this._showSortingOptions = false;
  }

  resetFilters() {
    this.formFilters.patchValue({
      state: SelectSessionState.ACTIVE,
      time: SelectSessionTime.TODAY,
      dataRange: CU.dateCalcToArrayOfDates(SelectSessionTime.TODAY)
    });
    this.customDateRange$.next(CU.dateCalcToArrayOfDates(SelectSessionTime.TODAY));
    this._isFilterActive = false;
  }

  getDynamicScrollHeight(): string {
    let baseHeight = 225; // Valore di base
    if (this._showSortingOptions || this._showFilters) {
      baseHeight = 375;
    }
    return `calc(100vh - ${baseHeight}px)`;
  }

  dateRangeConfirmationEvent(event) {
    console.log("NzOnOk", event);
    this.onEventByDateRange(event);
  }
}
