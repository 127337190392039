import { ICostRevenueSplit, IRevenue, IRevenuesEntities, ISimplePrices, ITariff, ITariffCalculateCostsInfo, ITariffCalculateCostsInfoParking, ITariffDescription, ITariffElements, ITariffRevenues } from "./ocpi/tariff.interface";
import { IPrice, ISessionOCPICdrDimension } from "./ocpi/ocpi-session.interface";
import { ISession } from "./session.interface";
import { CU } from "./utils";
import { IStatusNotification } from "./status-notification.interface";
import { IChargeDetailRecord } from "./ocpi/cdr.interface";
import { RevenueEntity } from "./enums/revenueEntity.enum";
import { RevenueSource } from "./enums/revenueSource.enum";
import { Currency } from "./enums/currency.enum";
import { OCPICdrDimensionType } from "./ocpi/enums/ocpiCdrDimensionType.enum";
import { RevenueDimension, RevenueDimensionValues } from "./enums/revenueDimension.enum";
import { OCPITariffDimension, OCPITariffDimensionValues } from "./ocpi/enums/ocpiTariffDimension.enum";
import { OCPIReservationRestriction } from "./ocpi/enums/ocpiReservationRestriction.enum";
import { OCPIDisplayText } from "./ocpi/ocpi-interfaces";

export namespace TariffUtil {

  export type DeepPartial<T> = {
    [K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> : T[K];
  };

  // la funzione deve prendere come parametro solo la sessione, dal momento che la tariffa è salvata nella sessione
  // all'interno, poi, invocherà una funzione più "pura" che prenderà come parametri una tariffa e dei valori di durata prenotazione, energia e occupazione in modo che
  // tale funzione interna possa essere anche invocata dal simulatore

  // Questo è l'unico punto dove vengono fatti i calcoli, e gli importi devono essere già con 2 cifre decimali soltanto (con attenzione a non effettuare troppi arrotondamenti successivi)

  export function calculateSessionCosts(session: ISession, statusNotifications: IStatusNotification[] = []): ISessionOCPICdrDimension[] {
    let reservationTimeInSeconds: number = 0;
    let energyInWatt: number = 0;
    let parkingTime: ITariffCalculateCostsInfoParking = {
      timeInSeconds: 0,
      gracePeriodAlreadyCalcualted: false
    };

    let connectorPower = getConnectorPower(session.connector);

    //if (!statusNotifications) return;

    // #region RESERVATION

    // Calcolo durata prenotazione e relativo costo
    if (!!session?.reservedAt) {
      let reservationEnd = new Date(session.bookingEndAt);
      const weAreOverBookingEnd = CU.dateAIsBigger(new Date(), new Date(session.bookingEndAt));
      if (!weAreOverBookingEnd) {
        reservationEnd = new Date();
      }
      // Se l'utente ha avviato la ricarica
      if (!!session?.startedAt) {
        reservationEnd = new Date(session.startedAt);
      }

      reservationTimeInSeconds = CU.getDateDiffInSecondsWithOneOnZero(new Date(session.reservedAt), reservationEnd);
    }

    // #endregion RESERVATION

    // #region ENERGY
    // calcolo volume energia    
    energyInWatt = session.energy ? session.energy : 0;
    // #endregion ENERGY

    // #region PARKING
    // calcolo tempo occupazione

    // Se sono in una sessione OCPI, mi arriva il dato del parcheggio già netto ed ha precedenza sui nostri calcoli
    if (session.occupationTime) {
      parkingTime = {
        timeInSeconds: session.occupationTime * 3600,
        gracePeriodAlreadyCalcualted: true
      }
    }
    else {
      let parkingTimeInSeconds: 0;
      let secondsFromFinishedChargingToEndSession: number = 0;
      if (session.stoppedAt) {
        const sessionParkingEnd = session.endedAt ? new Date(session.endedAt) : new Date(); // perchè se session.endedAt è null, new Date(null) è 1/1/1970!
        secondsFromFinishedChargingToEndSession = CU.getDateDiffInSeconds(sessionParkingEnd, new Date(session.stoppedAt));

        // Controllo la presenza di eventuali restrictions nella tariffa relativi ad intervalli temporali in cui si paga il parcheggio
        if (session.tariffJson) {
          const sessionTariff: ITariff = session.tariffJson;
          const parkingPriceComponents = getDimensionElements(sessionTariff, OCPITariffDimension.PARKING_TIME, connectorPower);

          if (parkingPriceComponents && parkingPriceComponents.length > 0) {
            const parikingPriceComponentsWithTimeRestrictions = parkingPriceComponents.filter(c => c.restrictions && c.restrictions.startTime && c.restrictions.endTime);
            if (parikingPriceComponentsWithTimeRestrictions && parikingPriceComponentsWithTimeRestrictions.length > 0) {
              // ci sono delle restrictions (per il momento ne gestiamo una e basta) che agiscono in base alla fascia oraria
              secondsFromFinishedChargingToEndSession = calculateSecondsInTariffElementWithStartTimeEndTime(parikingPriceComponentsWithTimeRestrictions[0], session.stoppedAt, sessionParkingEnd);
            }
          }
        }
      }
      parkingTimeInSeconds += secondsFromFinishedChargingToEndSession;

      parkingTime = {
        timeInSeconds: parkingTimeInSeconds,
        gracePeriodAlreadyCalcualted: false
      }
    }
    /*
    //
    // Per il momento non eseguo tale calcolo, dal momento che dovrei incrociare il calcolo con le restriction della tariffa
    //
 
    
    // se durante il periodo di ricarica si sono succeduti più eventi 
    // "SuspendedEV" -> "Charging", dobbiamo considerare i periodi in cui la sessione è stata in "SuspendedEV" (se la duarata è stata maggiore di una certa soglia, ad es 1 min.)
    // come tempo di occupazione
 
    let secondsFromSuspendedEVToCharging = 0;
    // ordino gli stati per createdAt
    const statusNotificationsSortedByCreatedAt = statusNotifications.sort((a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
    // ciclo gli stati ordinati
    for (let i = 0; i < statusNotificationsSortedByCreatedAt.length; i++) {
      const statusNotification = statusNotificationsSortedByCreatedAt[i];
      const nextstatusNotification = statusNotificationsSortedByCreatedAt[i + 1];
      // trovo la sequenza "SuspendedEV" -> "Charging"
      if (statusNotification?.status === OCPPComunication.InnerState.SuspendedEV && (nextstatusNotification && nextstatusNotification?.status === OCPPComunication.InnerState.Charging)) {
        secondsFromSuspendedEVToCharging += CU.getDateDiffInSeconds(nextstatusNotification.createdAt, statusNotification.createdAt)
      }
    }
    const lastStatusNotification = statusNotificationsSortedByCreatedAt[statusNotificationsSortedByCreatedAt.length - 1];
    if (lastStatusNotification?.status === OCPPComunication.InnerState.SuspendedEV) {
      secondsFromSuspendedEVToCharging += CU.getDateDiffInSeconds(new Date(), lastStatusNotification.createdAt);
    }
    if (secondsFromSuspendedEVToCharging > 60) parkingTimeInSeconds += secondsFromSuspendedEVToCharging;
    parkingTimeInSeconds += secondsFromFinishedChargingToEndSession;
    */
    // #endregion PARKING

    const info: ITariffCalculateCostsInfo = {
      reservationTimeInSeconds,
      energyInWatt,
      parking: parkingTime,
      tariff: session.tariffJson,
      connectorPower,
      withRevenueIncrement: true
    }
    return calculateCosts(info);
  }

  //Deve essere possibile richiamare tale funzione anche da simulatore dell'admin
  export function calculateCosts(info: ITariffCalculateCostsInfo): ISessionOCPICdrDimension[] {
    if (!info.connectorPower) {
      info.connectorPower = 300000;
    }

    let costs: ISessionOCPICdrDimension[] = [];

    let reservationDimensionCosts: ISessionOCPICdrDimension = {
      price: { inclVat: 0, exclVat: 0, vat: 22 },
      type: OCPICdrDimensionType.RESERVATION_TIME,
      volume: 0
    }

    let energyDimensionCosts: ISessionOCPICdrDimension = {
      price: { inclVat: 0, exclVat: 0, vat: 22 },
      type: OCPICdrDimensionType.ENERGY,
      volume: 0
    }

    let parkingDimensionCosts: ISessionOCPICdrDimension = {
      price: { inclVat: 0, exclVat: 0, vat: 0 },
      type: OCPICdrDimensionType.PARKING_TIME,
      volume: 0
    }
    // #region RESERVATION

    // Se la prenotazione ha avuto una durata, cerco di calcolarne il costo
    if (info.reservationTimeInSeconds > 0) {
      // Cerco il blocco "Reservation" all'interno della definizione della tariffa      
      let validReservationElement = getDimensionElements(info.tariff, OCPITariffDimension.RESERVATION, info.connectorPower);

      // Il tempo trascorso in prenotazione in teoria non dovrebbe mai essere maggiore di quanto indicato nella tariffa, 
      // perchè un cron controlla le prenotazioni più lunghe e le chiude d'ufficio
      if (validReservationElement.length > 0 && validReservationElement[0].restrictions?.maxDuration && info.reservationTimeInSeconds > validReservationElement[0].restrictions?.maxDuration) {
        info.reservationTimeInSeconds = validReservationElement[0].restrictions?.maxDuration;
      }

      const reservationComponent = validReservationElement[0]?.priceComponents[0]; // assumo ci sia un solo element e un solo price component per reservation

      if (reservationComponent) {
        const revenueReservation = getDimensionRevenue(info.tariff, RevenueDimension.RESERVATION);
        const reservationPriceForHour = info.withRevenueIncrement ? calculatePriceWithRevenue(revenueReservation, reservationComponent.price) : reservationComponent.price;

        let reservationVAT = getDimensionVAT(info.tariff, OCPITariffDimension.RESERVATION, info.connectorPower);

        if (reservationComponent.type === OCPITariffDimension.FLAT) {
          // Se il tipo è flat, vuol dire che pago la stessa cifra indipendentemente dal tempo trascorso
          const reservationCostExclVatRounded = CU.roundNumber(reservationPriceForHour, 2);

          // calcolo il costo dell'energia con vat. Prendo il dato già arrotondato
          // per spiegazione del perchè si prende il prezzo arrotondato vedi sezione energia
          const reservationCostWithVatRounded = CU.roundNumber(CU.calcPriceWithVat(reservationCostExclVatRounded, reservationVAT));

          reservationDimensionCosts.price.inclVat = reservationCostWithVatRounded;
          reservationDimensionCosts.price.exclVat = reservationCostExclVatRounded;
          reservationDimensionCosts.price.vat = reservationVAT;
          reservationDimensionCosts.volume = info.reservationTimeInSeconds;
        }
        else {
          // calcolo il numero di secondi che verranno calcolati effettivamente, considerando lo step
          const reservationTimeInSecondsWithStep = Math.ceil((info.reservationTimeInSeconds / reservationComponent.stepSize)) * reservationComponent.stepSize;
          // calcolo il prezzo al secondo, visto che sulla tariffa è orario
          const reservationPriceForSecond = reservationPriceForHour / 3600;

          // calcolo il costo senza VAT arrotondato
          const reservationCostExclVatRounded = CU.roundNumber((reservationPriceForSecond * reservationTimeInSecondsWithStep), 2);

          // calcolo il costo dell'energia con vat. Prendo il dato già arrotondato
          // per spiegazione del perchè si prende il prezzo arrotondato vedi sezione energia
          const reservationCostWithVatRounded = CU.roundNumber(CU.calcPriceWithVat(reservationCostExclVatRounded, reservationVAT));

          reservationDimensionCosts.price.inclVat = reservationCostWithVatRounded;
          reservationDimensionCosts.price.exclVat = reservationCostExclVatRounded;
          reservationDimensionCosts.price.vat = reservationVAT;
          reservationDimensionCosts.volume = info.reservationTimeInSeconds;
        }
      }
    }

    costs.push(reservationDimensionCosts);

    // #endregion RESERVATION

    // #region ENERGY
    // Se la prenotazione ha avuto una durata, cerco di calcolarne il costo
    if (info.energyInWatt > 0) {
      const revenueEnergy = getDimensionRevenue(info.tariff, RevenueDimension.ENERGY);
      const energyElements = getDimensionElements(info.tariff, OCPITariffDimension.ENERGY, info.connectorPower);
      if (energyElements && energyElements.length > 0) {
        const energyPriceComponent = energyElements[0].priceComponents[0]; // assumo ci sia un solo element e un solo price component per energy
        const energyPriceForKW = info.withRevenueIncrement ? calculatePriceWithRevenue(revenueEnergy, energyPriceComponent.price) : energyPriceComponent.price;

        // calcolo l'energia che verrà calcolata effettivamente, considerando lo step
        const stepSize = energyPriceComponent.stepSize ?? 1;
        const energyInWattWithStep = Math.ceil((info.energyInWatt / stepSize)) * stepSize;
        const energyInKw = energyInWattWithStep / 1000;

        // calcolo il costo dell'energia senza vat 
        const energyCost = energyInKw * energyPriceForKW;

        // calcolo il costo senza VAT arrotondato
        const energyCostExclVatRounded = CU.roundNumber(energyCost, 2);

        // calcolo il moltiplicatore di vat
        let energyElementVat = getDimensionVAT(info.tariff, OCPITariffDimension.ENERGY, info.connectorPower);

        // calcolo il costo dell'energia con vat. Prendo il dato già arrotondato, altrimenti rischio
        // di avere incoerenza fra dato arrotondato senza VAT e con VAT
        // ad es: se dato senza VAT non arrotondato = 0.5139750000000001
        // dato senza VAT arrotondato = 0.51
        // dato con VAT arrotondato prendendo come base 0.5139750000000001 -> 0.672049 -> 0.63
        // dato con VAT arrotondato prendendo come base 0.51 -> 0.62
        const energyCostInclVatRounded = CU.roundNumber(CU.calcPriceWithVat(energyCostExclVatRounded, energyElementVat));

        energyDimensionCosts.price.inclVat = energyCostInclVatRounded;
        energyDimensionCosts.price.exclVat = energyCostExclVatRounded;
        energyDimensionCosts.price.vat = energyElementVat;
        energyDimensionCosts.volume = info.energyInWatt;
      }
    }

    costs.push(energyDimensionCosts);

    // #endregion ENERGY

    // #region PARKING

    if (!!info.parking) {
      let parkingCost = 0;
      let parkingCostWithVAT = 0;
      let parkingVAT = 0;

      let parkingTimeSeconds: number = info.parking?.timeInSeconds;

      if (parkingTimeSeconds > 0) {
        let parkingTariffElement = getDimensionElements(info.tariff, OCPITariffDimension.PARKING_TIME, info.connectorPower);
        const revenueParking = getDimensionRevenue(info.tariff, RevenueDimension.PARKING);
        parkingTariffElement = parkingTariffElement.sort(compareRestrictionByMinDuration);

        for (const tariffElement of parkingTariffElement) {
          const priceComponent = tariffElement.priceComponents[0];
          if (priceComponent.type === OCPICdrDimensionType.PARKING_TIME) {
            if (parkingTimeSeconds < 0) continue;
            if (info.parking?.gracePeriodAlreadyCalcualted && priceComponent.price === 0) continue; // Se il Grace Period è stato già considerato, salto i periodi gratuiti

            let parkingTimeVat = getDimensionVAT(info.tariff, OCPITariffDimension.PARKING_TIME, info.connectorPower);

            const parkingTimePriceForHour = info.withRevenueIncrement ? calculatePriceWithRevenue(revenueParking, priceComponent.price) : priceComponent.price;
            // calcolo il numero di secondi che verranno calcolati effettivamente, considerando lo step
            const parkingTimeInSecondsWithStep = Math.ceil((parkingTimeSeconds / priceComponent.stepSize)) * priceComponent.stepSize;
            // calcolo il prezzo al secondo, visto che sulla tariffa è orario
            const parkingTimePriceForSecond = parkingTimePriceForHour / 3600;

            parkingCost += CU.roundNumber(parkingTimeInSecondsWithStep * parkingTimePriceForSecond);
            parkingCostWithVAT += CU.calcPriceWithVat(parkingCost, parkingTimeVat);
            parkingTimeSeconds -= tariffElement.restrictions?.maxDuration ?? 0;

            parkingVAT = parkingTimeVat;
          }
        }
      }

      const parkingCostExclVatRounded = CU.roundNumber(parkingCost);
      const parkingCostInclVatRounded = CU.roundNumber(parkingCostWithVAT);

      parkingDimensionCosts.price.inclVat = parkingCostInclVatRounded;
      parkingDimensionCosts.price.exclVat = parkingCostExclVatRounded;
      parkingDimensionCosts.price.vat = parkingVAT;
      parkingDimensionCosts.volume = info.parking?.timeInSeconds;
      costs.push(parkingDimensionCosts);
    }
    // #endregion PARKING

    return costs;
  }

  function compareRestrictionByMinDuration(a: ITariffElements, b: ITariffElements) {
    if (a.restrictions) {
      if (b.restrictions) {
        if (a.restrictions.minDuration < b.restrictions.minDuration) {
          return -1;
        }
        if (a.restrictions.minDuration > b.restrictions.minDuration) {
          return 1;
        }
        return 0;
      }
      else {
        return -1;
      }
    }
    else if (!b.restrictions) {
      return 0;
    } {
      return 1
    }
  }

  function compareRestrictionByMaxPower(a: ITariffElements, b: ITariffElements) {
    if (a.restrictions) {
      if (b.restrictions) {
        if (a.restrictions.maxPower < b.restrictions.maxPower) {
          return -1;
        }
        if (a.restrictions.maxPower > b.restrictions.maxPower) {
          return 1;
        }
        return 0;
      }
      else {
        return -1;
      }
    }
    else if (!b.restrictions) {
      return 0;
    } {
      return 1
    }
  }

  function getPowerRestrictionsForConnector(elements: ITariffElements[], connectorPower: number) {
    let elementsToReturn: ITariffElements[] = [];
    let connectorPowerInKW = connectorPower / 1000;
    const fallbackElements = elements.filter(e => CU.isNullOrUndefined(e.restrictions?.maxPower));

    const elementsWithPowerRestriction = elements.filter(e => e.restrictions?.maxPower && connectorPowerInKW <= e.restrictions?.maxPower);
    if (elementsWithPowerRestriction && elementsWithPowerRestriction.length > 0) {
      elementsWithPowerRestriction.sort((a, b) => compareRestrictionByMaxPower(a, b));
      let elementsToAnalyze: ITariffElements[] = JSON.parse(JSON.stringify(elementsWithPowerRestriction));
      // Sono già in ordine crescente e filtrati per maxPower > connectorPowerInKW...il primo elemento è per forza valido;
      // devo controllare se ci sono altre restrictions per lo stesso valore
      const firstItemValid = elementsToAnalyze.shift();
      const maxPowerSelected = firstItemValid.restrictions?.maxPower;
      elementsToReturn.push(firstItemValid);

      for (let index = 0; index < elementsToAnalyze.length; index++) {
        const element = elementsToAnalyze[index];
        if (element.restrictions?.maxPower === maxPowerSelected) {
          elementsToReturn.push(element);
        }
      }
    }
    else {
      elementsToReturn = fallbackElements;
    }

    return elementsToReturn;
  }

  export function generateCostRevenueSplits(entities: IRevenuesEntities, tariff: ITariff, cdr: IChargeDetailRecord, connectorPower: number): DeepPartial<ICostRevenueSplit[]> {
    const revenueArr: DeepPartial<ICostRevenueSplit[]> = [];

    const info: ITariffCalculateCostsInfo = {
      reservationTimeInSeconds: cdr.totalReservationTime,
      energyInWatt: cdr.totalEnergy,
      parking: {
        timeInSeconds: cdr.totalParkingTime,
        gracePeriodAlreadyCalcualted: false
      },
      tariff,
      connectorPower,
      withRevenueIncrement: false
    }

    let costs = calculateCosts(info);
    let rCost = costs.find(c => c.type === OCPICdrDimensionType.RESERVATION_TIME)?.price;
    let eCost = costs.find(c => c.type === OCPICdrDimensionType.ENERGY)?.price;
    let pCost = costs.find(c => c.type === OCPICdrDimensionType.PARKING_TIME)?.price;
    let revenues: IRevenue[] = createRevenues(tariff, rCost, eCost, pCost);

    revenues.forEach(element => {
      let amount = CU.roundNumber(element.amount);
      revenueArr.push({
        entity: element.entity,
        userId: entities[Object.keys(RevenueEntity)[element.entity]],
        amount,
        isInCredit: amount > 0,
        sessionId: cdr.sessionId,
        cdrId: cdr.id,
        currency: element.currency,
        amountWithVat: element.amountWithVat,
        source: tariff.REVENUE?.find(c => c.type === RevenueDimension.ENERGY) ? RevenueSource.Tariff : RevenueSource.Default,
        type: element.type,
        tariffId: tariff.id,
      })
    });
    return revenueArr;
  }

  // Questa funzione deve essere possibile richiamarla anche da simulatore dell'admin
  export function calculateRevenues(reservationTimeInSeconds: number, energyInWatt: number, secondsFromStoppedAt: number, tariff: ITariff, connectorPower: number) {

    let revenueEntities = {
      cpo: 0,
      emsp: 0,
      podOwner: 0,
      brandOwner: 0,
      deviceOwner: 0,
      installationOwner: 0
    }

    const info: ITariffCalculateCostsInfo = {
      reservationTimeInSeconds,
      energyInWatt,
      parking: {
        timeInSeconds: secondsFromStoppedAt,
        gracePeriodAlreadyCalcualted: false
      },
      tariff,
      connectorPower,
      withRevenueIncrement: false
    }

    let costs = calculateCosts(info);
    let rCost = costs.find(c => c.type === OCPICdrDimensionType.RESERVATION_TIME)?.price;
    let eCost = costs.find(c => c.type === OCPICdrDimensionType.ENERGY)?.price;
    let pCost = costs.find(c => c.type === OCPICdrDimensionType.PARKING_TIME)?.price;
    let revenues: IRevenue[] = createRevenues(tariff, rCost, eCost, pCost);
    Object.keys(revenueEntities).forEach((element) => {
      let keyAmounts = revenues.filter(c => c.key === element);
      const sum = keyAmounts.reduce((accumulator, object) => {
        return accumulator + object.amountWithVat;
      }, 0);

      revenueEntities[element] = sum;
    });

    return revenueEntities;
  }

  export function createRevenues(tariff: ITariff, reservationCost: IPrice, energyCost: IPrice, parkingCost: IPrice): IRevenue[] {
    const revenueArr: IRevenue[] = []

    if (reservationCost) {
      let reservationVAT = getDimensionVAT(tariff, OCPITariffDimension.RESERVATION);
      const revenueReservation = getDimensionRevenue(tariff, RevenueDimension.RESERVATION);
      const revs = calculateRevsWithCost(revenueReservation, reservationCost.exclVat);
      for (const key in revs) {
        if (revs.hasOwnProperty(key)) {
          const entity = RevenueEntity[key];
          const amount = revs[key];
          const amountWithVat = CU.roundNumber(CU.calcPriceWithVat(amount, reservationVAT), 3);
          if (!(entity in Object.values(RevenueEntity))) {
            throw new Error('No entity found for key: ' + key + '    revs: ' + JSON.stringify(revs) + '   - revenue entity: ' + JSON.stringify(RevenueEntity));
          }
          revenueArr.push({
            key,
            entity,
            amount,
            currency: Currency.EUR,
            amountWithVat: amountWithVat,
            source: tariff.REVENUE?.find(c => c.type === RevenueDimension.RESERVATION) ? RevenueSource.Tariff : RevenueSource.Default,
            type: RevenueDimension.RESERVATION
          });
        }
      }
    }

    if (energyCost) {
      let energyElementVAT = getDimensionVAT(tariff, OCPITariffDimension.ENERGY);
      const revenueEnergy = getDimensionRevenue(tariff, RevenueDimension.ENERGY);
      const revs = calculateRevsWithCost(revenueEnergy, energyCost.exclVat);
      for (const key in revs) {
        if (revs.hasOwnProperty(key)) {
          const entity = RevenueEntity[key];
          const amount = revs[key];
          const amountWithVat = CU.roundNumber(CU.calcPriceWithVat(amount, energyElementVAT), 3);
          if (!(entity in Object.values(RevenueEntity))) {
            throw new Error('No entity found for key: ' + key + '    revs: ' + JSON.stringify(revs) + '   - revenue entity: ' + JSON.stringify(RevenueEntity));
          }
          revenueArr.push({
            key,
            entity,
            amount,
            currency: Currency.EUR,
            amountWithVat,
            source: tariff.REVENUE?.find(c => c.type === RevenueDimension.ENERGY) ? RevenueSource.Tariff : RevenueSource.Default,
            type: RevenueDimension.ENERGY
          });
        }
      }
    }

    if (parkingCost) {
      let parkingElementVAT = getDimensionVAT(tariff, OCPITariffDimension.PARKING_TIME);
      const revenueParking = getDimensionRevenue(tariff, RevenueDimension.PARKING);
      const revs = calculateRevsWithCost(revenueParking, parkingCost.exclVat);
      for (const key in revs) {
        if (revs.hasOwnProperty(key)) {
          const entity = RevenueEntity[key];
          const amount = revs[key];
          const amountWithVat = CU.roundNumber(CU.calcPriceWithVat(amount, parkingElementVAT), 3);
          if (!(entity in Object.values(RevenueEntity))) {
            throw new Error('No entity found for key: ' + key + '    revs: ' + JSON.stringify(revs) + '   - revenue entity: ' + JSON.stringify(RevenueEntity));
          }
          revenueArr.push({
            key,
            entity,
            amount,
            currency: Currency.EUR,
            amountWithVat,
            source: tariff.REVENUE?.find(c => c.type === RevenueDimension.PARKING) ? RevenueSource.Tariff : RevenueSource.Default,
            type: RevenueDimension.PARKING
          });
        }
      }
    }

    return revenueArr;
  }

  export function calculatePriceWithRevenue(revenueObj: any, price: number): number {
    if (!revenueObj) return price
    if (price <= 0) return 0;

    let totalPercentage: number = 0;

    for (let key in revenueObj) {
      if (revenueObj.hasOwnProperty(key) && key !== "type") {
        totalPercentage += revenueObj[key];
      }
    }

    if (totalPercentage <= 0) {
      return 0;
    }

    return (price * CU.roundNumber(totalPercentage / 100));
  }

  export function getPrices(tariff: ITariff, connectorPower?: number): ISimplePrices {
    let ePriceExclVat: number = 0;
    let rPriceExclVat: number = 0;
    let oPriceExclVat: number = 0;

    let ePriceInclVat: number = 0;
    let rPriceInclVat: number = 0;
    let oPriceInclVat: number = 0;


    const validReservationElements = getDimensionElements(tariff, OCPITariffDimension.RESERVATION, connectorPower);
    if (validReservationElements) {
      const revenueReservation = getDimensionRevenue(tariff, RevenueDimension.RESERVATION);
      const rightReservationVat = getDimensionVAT(tariff, OCPITariffDimension.RESERVATION, connectorPower);
      const reservationPriceComponent = validReservationElements[0].priceComponents.find(c => !CU.isNullOrUndefined(c.price));
      rPriceExclVat = calculatePriceWithRevenue(revenueReservation, reservationPriceComponent.price);
      rPriceInclVat = CU.calcPriceWithVat(rPriceExclVat, rightReservationVat);
    }

    const validEnergyElements = getDimensionElements(tariff, OCPITariffDimension.ENERGY, connectorPower);
    if (validEnergyElements) {
      const revenueEnergy = getDimensionRevenue(tariff, RevenueDimension.ENERGY);
      const rightEnergyVat = getDimensionVAT(tariff, OCPITariffDimension.ENERGY, connectorPower);
      const energyPriceComponent = validEnergyElements[0].priceComponents.find(c => !CU.isNullOrUndefined(c.price));
      ePriceExclVat = calculatePriceWithRevenue(revenueEnergy, energyPriceComponent.price);
      ePriceInclVat = CU.calcPriceWithVat(ePriceExclVat, rightEnergyVat);
    }

    const validParkingElements = getDimensionElements(tariff, OCPITariffDimension.PARKING_TIME, connectorPower);
    if (validParkingElements) {
      const revenueParking = getDimensionRevenue(tariff, RevenueDimension.PARKING);
      const rightParkingVat = getDimensionVAT(tariff, OCPITariffDimension.PARKING_TIME, connectorPower);
      const parkingPriceComponent = validParkingElements[validParkingElements.length - 1].priceComponents.find(c => !CU.isNullOrUndefined(c.price));
      oPriceExclVat = calculatePriceWithRevenue(revenueParking, parkingPriceComponent.price);
      oPriceInclVat = CU.calcPriceWithVat(oPriceExclVat, rightParkingVat);
    }

    return {
      ePrice: {
        exclVat: ePriceExclVat,
        inclVat: ePriceInclVat,
      },
      rPrice: {
        exclVat: rPriceExclVat,
        inclVat: rPriceInclVat,
      },
      oPrice: {
        exclVat: oPriceExclVat,
        inclVat: oPriceInclVat,
      },
    };
  }

  export function getDimensionVAT(tariff: ITariff, dimension: OCPITariffDimensionValues, connectorPower?: number): number {
    let elementVat = 22;

    let validTariffElements = getDimensionElements(tariff, dimension, connectorPower);
    if (validTariffElements) {
      let firstElementWithVat = validTariffElements.find(c => c.priceComponents.some(c => !CU.isNullOrUndefined(c.vat)));
      if (firstElementWithVat) {
        elementVat = firstElementWithVat.priceComponents.find(c => !CU.isNullOrUndefined(c.vat))?.vat;
      }
    }

    return elementVat;
  }

  export function getDimensionElements(tariff: ITariff, dimension: OCPITariffDimensionValues, connectorPower?: number): ITariffElements[] {
    let dimensionElements: ITariffElements[] = [];
    switch (dimension) {
      case OCPITariffDimension.RESERVATION:
        {
          const validReservationElements = tariff.elements.filter((element) => element.restrictions?.reservation === OCPIReservationRestriction.RESERVATION);
          if (validReservationElements) {
            if (connectorPower) {
              dimensionElements = getPowerRestrictionsForConnector(validReservationElements, connectorPower);
            }
            else {
              dimensionElements = validReservationElements;
            }
          }
        }
        break;

      case OCPITariffDimension.ENERGY:
        {
          let validEnergyElements = tariff.elements.filter(p => p.priceComponents.some(o => o.type === OCPITariffDimension.ENERGY));
          if (validEnergyElements) {
            if (connectorPower) {
              dimensionElements = getPowerRestrictionsForConnector(validEnergyElements, connectorPower);
            } else {
              dimensionElements = validEnergyElements;
            }
          }
        }
        break;

      case OCPITariffDimension.PARKING_TIME:
        {
          let validParkingElements = tariff.elements.filter(p => p.priceComponents.some(o => o.type === OCPITariffDimension.PARKING_TIME));
          if (validParkingElements) {
            if (connectorPower) {
              dimensionElements = getPowerRestrictionsForConnector(validParkingElements, connectorPower);
            }
            else {
              dimensionElements = validParkingElements;
            }
          }
        }
        break;

      default:
        break;
    }

    return dimensionElements;
  }

  export function getDimensionRevenue(tariff: ITariff, dimension: RevenueDimensionValues): ITariffRevenues {
    let defaultRevenue = tariff.REVENUE?.find(c => c.type === RevenueDimension.DEFAULT);
    switch (dimension) {
      case RevenueDimension.RESERVATION:
        return tariff.REVENUE?.find(c => c.type === RevenueDimension.RESERVATION) ?? defaultRevenue;
      case RevenueDimension.ENERGY:
        return tariff.REVENUE?.find(c => c.type === RevenueDimension.ENERGY) ?? defaultRevenue;
      case RevenueDimension.PARKING:
        return tariff.REVENUE?.find(c => c.type === RevenueDimension.PARKING) ?? defaultRevenue;
    }
    return defaultRevenue;
  }

  export function calculateRevsWithCost(revenue: any, cost: number) {
    const splitRevenues = {};

    for (const key in revenue) {
      if (revenue.hasOwnProperty(key) && key !== "type") {
        splitRevenues[key] = cost * (CU.roundNumber(revenue[key] / 100, 3));
      }
    }

    for (const key in splitRevenues) {
      if (splitRevenues.hasOwnProperty(key) && splitRevenues[key] === 0) {
        delete splitRevenues[key];
      }
    }

    return splitRevenues as any;
  }

  export function checkCrsValidity(revenues: ICostRevenueSplit[], cost: number): boolean {
    const sumAmounts = (arr) => {
      return arr.reduce((total, item) => total + item.amount, 0);
    };
    const inCreditRevenues = revenues.filter(c => c.isInCredit);
    const totalCreditAmount = sumAmounts(inCreditRevenues);

    const inDebitRevenues = revenues.filter(c => !c.isInCredit);
    const totalDebitAmount = sumAmounts(inDebitRevenues);

    // aggiungo un minimo di tolleranza, in modo da non essere rigorosi in casi impossibili:
    // ad es: 0.61€ da dividere al 50% fra due parti...verrebbe 0.305, ma visto l'arrotondamento alla seconda cifra,
    // verrebbero due revenue da 0.30 per un totale di 0.60

    const result = cost + (-1 * totalDebitAmount) - totalCreditAmount;
    const rounderResult = CU.roundNumber(result);

    // accetto un risultato con 1% di errore, ossia compreso tra -0.01 e 0.01
    const tolerance = 0.01;
    return (rounderResult > (-1 * tolerance)) && (rounderResult < tolerance)
  }

  export function calculateSecondsInTariffElementWithStartTimeEndTime(tariffElement: ITariffElements, startDate: Date, endDate: Date): number {
    const restrictionStartHour = tariffElement.restrictions.startTime.split(":")[0];
    const restrictionStartMinute = tariffElement.restrictions.startTime.split(":")[1];
    const restrictionEndHour = tariffElement.restrictions.endTime.split(":")[0];
    const restrictionEndMinute = tariffElement.restrictions.endTime.split(":")[1];

    const restrictionStartSessionBased = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), parseInt(restrictionStartHour), parseInt(restrictionStartMinute));
    const restrictionEndSessionBased = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), parseInt(restrictionEndHour), parseInt(restrictionEndMinute));
    let secondsInsideRestriction = 0;

    // controllo se c'è sovrapposizione tra i due range di date
    if (startDate < restrictionEndSessionBased && restrictionStartSessionBased < endDate) {

      // Devo considerare anche l'intervallo copra più giorni, quindi devo scorrere tutti i giorni
      const dayStart = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0);
      const dayEnd = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0);

      const dayDifferenceFromStartToEnd = CU.getDayDiff(dayStart, dayEnd);
      for (let index = 0; index <= dayDifferenceFromStartToEnd; index++) {

        let sParkingStart = startDate;
        let sParkingEnd = endDate;

        let currentDay = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0);
        currentDay = CU.addMinutes(currentDay, 1440 * index);

        let restrictionCurrentDayStart = new Date(currentDay.getFullYear(), currentDay.getMonth(), currentDay.getDate(), parseInt(restrictionStartHour), parseInt(restrictionStartMinute));
        let restrictionCurrentDayEnd = new Date(currentDay.getFullYear(), currentDay.getMonth(), currentDay.getDate(), parseInt(restrictionEndHour), parseInt(restrictionEndMinute));

        if (index === 0 && dayDifferenceFromStartToEnd > 0) {
          sParkingEnd = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 23, 59);
        }

        if (index > 0) {
          sParkingStart = new Date(currentDay.getFullYear(), currentDay.getMonth(), currentDay.getDate(), 0, 0);
          if (index < dayDifferenceFromStartToEnd) {
            sParkingEnd = new Date(currentDay.getFullYear(), currentDay.getMonth(), currentDay.getDate(), 23, 59);
          }
        }

        if (sParkingStart <= restrictionCurrentDayStart) {
          if (sParkingEnd >= restrictionCurrentDayEnd) {
            // sono nel caso in cui tutta la sessione di parking ricade nell'intervallo definito dalla restriction
            secondsInsideRestriction += CU.getDateDiffInSeconds(restrictionCurrentDayStart, restrictionCurrentDayEnd);
          }
          else if (sParkingEnd >= restrictionCurrentDayStart) {
            // sono nel caso in cui la sessione di parking è finita prima della fine delle restriction
            secondsInsideRestriction += CU.getDateDiffInSeconds(restrictionCurrentDayStart, sParkingEnd);
          }
        }
        else if (sParkingStart < restrictionCurrentDayEnd) {
          if (sParkingEnd >= restrictionCurrentDayEnd) {
            // sono nel caso in cui tutta la sessione di parking inizia dopo l'inizio delle restriction e finisce dopo la fine delle restrictions
            secondsInsideRestriction += CU.getDateDiffInSeconds(sParkingStart, restrictionCurrentDayEnd);
          }
          else {
            // sono nel caso in cui tutta la sessione di parking inizia dopo l'inizio delle restriction e finisce entro la fine delle restrictions
            secondsInsideRestriction += CU.getDateDiffInSeconds(sParkingStart, sParkingEnd);
          }
        }
      }

    }
    return secondsInsideRestriction;
  }

  export function extractPlaceholders(tariff: any): { [key: string]: string | null } { // Tariff any perchè è un template al momento, e non andrebbe bene come ITariff 

    const placeholders: { [key: string]: string | null } = {};

    function traverseObject(obj: any, path: string = "") {
      for (const key in obj) {
        const newPath = path ? `${path}.${key}` : key;
        if (typeof obj[key] === "string" && obj[key].startsWith("#") && obj[key].endsWith("#")) {
          placeholders[obj[key]] = null;
        } else if (typeof obj[key] === "object") {
          traverseObject(obj[key], newPath);
        }
      }
    }
    traverseObject(tariff);

    return placeholders;
  }

  export function replacePlaceholders(tariff: any, placeholderValues: { [key: string]: any }): any {
    function traverseObject(obj: any) {
      for (const key in obj) {
        if (typeof obj[key] === "string" && obj[key].startsWith("#") && obj[key].endsWith("#")) {
          const placeholder = obj[key];
          if (placeholderValues.hasOwnProperty(placeholder)) {
            const placeholderType = placeholder.slice(1, -1).split("|")[1];
            if (placeholderType === "text") {
              obj[key] = String(placeholderValues[placeholder]);
            } else {
              obj[key] = placeholderValues[placeholder];
            }
          }
        } else if (typeof obj[key] === "object") {
          if (Array.isArray(obj[key])) {
            obj[key].forEach(element => {
              traverseObject(element);
            });

          } else {
            traverseObject(obj[key]);
          }
        }
      }
    }

    traverseObject(tariff);
    return tariff;
  }

  export function transformPlaceholder(placeholder: string) {
    const words = placeholder
      .slice(1, -1)
      .split('|')[0]
      .split('_')
      .map((word, index) => {
        if (index === 0) {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }
        return word.toLowerCase();
      });

    return words.join(' ');
  }

  export function createTariffAltText(tariff: ITariff): OCPIDisplayText[] {
    let tariffAltText: OCPIDisplayText[] = [];

    let tariffAltTextDescriptions_en: string[] = [];
    let tariffAltTextDescriptions_it: string[] = [];

    let energyPowers = tariff.elements.filter(c => c.priceComponents.some(p => p.type === OCPITariffDimension.ENERGY));
    energyPowers = energyPowers.sort((a, b) => compareRestrictionByMaxPower(a, b));

    let lastMaxPower = null;
    for (const element of energyPowers) {
      for (const pc of element.priceComponents) {
        if (pc.type === OCPITariffDimension.ENERGY) {
          const maxPower = element.restrictions?.maxPower;
          let rangeDescriptions = [];
          let connectorPower = 300000;
          if (maxPower) {
            connectorPower = maxPower * 1000;
            rangeDescriptions.push({ lang: "it", text: lastMaxPower ? `Da ${lastMaxPower}kW a ${maxPower}kW:` : `Fino a ${maxPower}kW:` });
            rangeDescriptions.push({ lang: "en", text: lastMaxPower ? `From ${lastMaxPower}kW to ${maxPower}kW:` : `Up to ${maxPower}kW:` });
            lastMaxPower = maxPower;
          }
          else {
            rangeDescriptions.push({ lang: "it", text: lastMaxPower ? `Da ${lastMaxPower}kW: ` : '' });
            rangeDescriptions.push({ lang: "en", text: lastMaxPower ? `From ${lastMaxPower}kW: ` : '' });
          }

          let rangeDescription_it = rangeDescriptions.find(c => c.lang == 'it');
          const descriptions_it = getTariffAltTextForConnectorPower(tariff, "it", connectorPower);
          const reservationDescription_it = descriptions_it.find(c => c.dimension === OCPITariffDimension.RESERVATION);
          const energyDescription_it = descriptions_it.find(c => c.dimension === OCPITariffDimension.ENERGY);
          const parkingDescription_it = descriptions_it.find(c => c.dimension === OCPITariffDimension.PARKING_TIME);
          tariffAltTextDescriptions_it.push(`${rangeDescription_it?.text}${reservationDescription_it?.description ?? ''} ${energyDescription_it.description ?? ''} ${parkingDescription_it?.description ?? ''}`.trim());


          let rangeDescription_en = rangeDescriptions.find(c => c.lang == 'en');
          const descriptions_en = getTariffAltTextForConnectorPower(tariff, "en", connectorPower);
          const reservationDescription_en = descriptions_en.find(c => c.dimension === OCPITariffDimension.RESERVATION);
          const energyDescription_en = descriptions_en.find(c => c.dimension === OCPITariffDimension.ENERGY);
          const parkingDescription_en = descriptions_en.find(c => c.dimension === OCPITariffDimension.PARKING_TIME);
          tariffAltTextDescriptions_en.push(`${rangeDescription_en?.text}${reservationDescription_en?.description ?? ''} ${energyDescription_en?.description ?? ''} ${parkingDescription_en?.description ?? ''}`.trim());
        }
      }
    }
    tariffAltText.push({ language: "it", text: tariffAltTextDescriptions_it.join(". ") });
    tariffAltText.push({ language: "en", text: tariffAltTextDescriptions_en.join(". ") });
    return tariffAltText;
  }

  export function getTariffAltTextForConnectorPower(tariff: ITariff, lang: string = "it", connectorPower?: number): ITariffDescription[] {
    if (lang !== "it" && lang !== "en") {
      lang = "en";
    }
    if (!connectorPower) {
      connectorPower = 500000; // metto il valore massimo perchè in questo modo comprende le tariffe che hanno più potenze e nella default non hanno il maxPower specificato
    }
    let descriptionItems: ITariffDescription[] = [];
    // Descrizione tariffa

    // Occupazione
    // Si assume che ci siano al massimo 2 elementi, uno per la parte gratuita (opzionale) e uno a pagamento per i minuti
    // Se ci sono più periodi a pagamento, va rivisto l'algoritmo

    let costs: ISessionOCPICdrDimension[] = [];
    let gracePeriodDurationInSeconds = 3600;

    let parkingTariffElement = getDimensionElements(tariff, OCPITariffDimension.PARKING_TIME, connectorPower);
    if (parkingTariffElement && parkingTariffElement.length > 0) {
      let parkingText_it = "";
      let quickParkingText_it = "";

      let parkingText_en = "";
      let quickParkingText_en = "";
      parkingTariffElement = parkingTariffElement.sort(compareRestrictionByMinDuration);
      for (let index = 0; index < parkingTariffElement.length; index++) {
        const element = parkingTariffElement[index];

        if (element.priceComponents[0].price === 0) { // è definito il graceperiod
          if (parkingTariffElement.length === 1) {// se c'è solo il grace period...è sempre gratis!
            parkingText_it = `Il parcheggio è gratuito.`;
            parkingText_en = `Parking is free.`;

            quickParkingText_it = `Gratis`;
            quickParkingText_en = `Free`;
          }
          else {
            gracePeriodDurationInSeconds = element.restrictions?.maxDuration;
            if (gracePeriodDurationInSeconds && gracePeriodDurationInSeconds > 0) {
              const maxDurationInMinutes = gracePeriodDurationInSeconds / 60;
              parkingText_it = `Il parcheggio è gratuito per i primi ${maxDurationInMinutes} minuti`;
              quickParkingText_it = `Gratis ${maxDurationInMinutes} min`;

              parkingText_en = `Parking is free for the first ${maxDurationInMinutes} minutes`;
              quickParkingText_en = `${maxDurationInMinutes} min free`;
            }
          }
        }
        else {
          const info: ITariffCalculateCostsInfo = {
            reservationTimeInSeconds: 60,
            energyInWatt: 1000,
            parking: {
              timeInSeconds: 60,
              gracePeriodAlreadyCalcualted: true
            },
            tariff,
            connectorPower,
            withRevenueIncrement: true
          }

          costs = calculateCosts(info);
          let pCost = costs.find(c => c.type === OCPICdrDimensionType.PARKING_TIME)?.price;
          if (parkingText_it) {
            parkingText_it = `${parkingText_it}, poi ha un costo di ${pCost.inclVat}€/min.`
            quickParkingText_it = `${quickParkingText_it}. Poi ${pCost.inclVat}€/min.`

            parkingText_en = `${parkingText_en}, then it costs ${pCost.inclVat}€/min.`
            quickParkingText_en = `${quickParkingText_en}. Then ${pCost.inclVat}€/min.`
          }
          else {
            parkingText_it = `Il parcheggio ha un costo di ${pCost.inclVat}€/min.`;
            quickParkingText_it = `${pCost.inclVat}€/min.`;

            parkingText_en = `Parking has a cost of ${pCost.inclVat}€/min.`;
            quickParkingText_en = `${pCost.inclVat}€/min.`
          }
        }
      }
      if (parkingText_it) {
        descriptionItems.push({
          lang: "it",
          dimension: OCPITariffDimension.PARKING_TIME,
          description: parkingText_it,
          quickDescription: quickParkingText_it
        });
      }

      if (parkingText_en) {
        descriptionItems.push({
          lang: "en",
          dimension: OCPITariffDimension.PARKING_TIME,
          description: parkingText_en,
          quickDescription: quickParkingText_en
        });
      }
    }

    // se non sono entrato nella parte a pagamento del parcheggio (e quindi non ho popolato costs), devo calcolare gli altri costi
    if (costs.length === 0) {

      const info: ITariffCalculateCostsInfo = {
        reservationTimeInSeconds: 60,
        energyInWatt: 1000,
        parking: {
          timeInSeconds: 0,
          gracePeriodAlreadyCalcualted: false
        },
        tariff,
        connectorPower,
        withRevenueIncrement: true
      }
      costs = calculateCosts(info);
    }

    // Prenotazione
    let validReservationElement = getDimensionElements(tariff, OCPITariffDimension.RESERVATION, connectorPower);
    if (validReservationElement && validReservationElement.length > 0) {
      let rCost = costs.find(c => c.type === OCPICdrDimensionType.RESERVATION_TIME)?.price;
      const reservationComponent = validReservationElement[0].priceComponents[0]; // assumo ce ne sia solo uno
      const reservationRestrictions = validReservationElement[0].restrictions;
      let reservationText_it = `La prenotazione`;
      let quickReservationText_it = "";

      let reservationText_en = `The reservation`;
      let quickReservationText_en = "";

      if (reservationRestrictions && reservationRestrictions.maxDuration) {
        const reservationDurationInMinutes = reservationRestrictions.maxDuration / 60;
        reservationText_it = `${reservationText_it} è valida per ${reservationDurationInMinutes} minuti`;
        quickReservationText_it = `${reservationDurationInMinutes} minuti`;

        reservationText_en = `${reservationText_en} is valid for ${reservationDurationInMinutes} minutes`;
        quickReservationText_en = `${reservationDurationInMinutes} minutes`;
      }
      if (rCost.inclVat > 0) {
        if (reservationComponent && reservationComponent.type === OCPITariffDimension.FLAT) {
          reservationText_it = `${reservationText_it} ed ha un costo fisso pari a ${rCost.inclVat}€.`;
          reservationText_en = `${reservationText_en} and has a fixed cost of ${rCost.inclVat}€.`;
        }
        else if (reservationComponent && reservationComponent.type === OCPITariffDimension.TIME) {
          reservationText_it = `${reservationText_it} ed ha un costo di ${rCost.inclVat}€/min.`;
          reservationText_en = `${reservationText_en} and it costs ${rCost.inclVat}€/min.`;
        }
      }
      else {
        reservationText_it = `${reservationText_it} ed è gratuita.`;
        reservationText_en = `${reservationText_en} and is free.`;
      }
      if (reservationText_it) {
        descriptionItems.push({
          lang: "it",
          dimension: OCPITariffDimension.RESERVATION,
          description: reservationText_it,
          quickDescription: quickReservationText_it
        });
      }

      if (reservationText_en) {
        descriptionItems.push({
          lang: "en",
          dimension: OCPITariffDimension.RESERVATION,
          description: reservationText_en,
          quickDescription: quickReservationText_en
        });
      }
    }

    // Energia
    let eCost = costs.find(c => c.type === OCPICdrDimensionType.ENERGY)?.price;
    let energyText_it = `Il costo dell'energia è di ${eCost.inclVat}€/kWh.`;
    let quickEnergyText_it = `${eCost.inclVat}€/kWh`;

    let energyText_en = `The cost of energy is ${eCost.inclVat}€/kWh.`;
    let quickEnergyText_en = `${eCost.inclVat}€/kWh`;

    descriptionItems.push({
      lang: "it",
      dimension: OCPITariffDimension.ENERGY,
      description: energyText_it,
      quickDescription: quickEnergyText_it
    });

    descriptionItems.push({
      lang: "en",
      dimension: OCPITariffDimension.ENERGY,
      description: energyText_en,
      quickDescription: quickEnergyText_en
    });

    return descriptionItems.filter(c => c.lang === lang);
  }

  export function getConnectorPower(connector: any) {
    return connector?.powerLimit ? connector.powerLimit : connector?.power;
  }
}