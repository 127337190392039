import { SelectCDRState, SelectCorrispettivoState, SelectCpo, SelectNumberOfConnector, SelectOnlineStationState, SelectPaymentState, SelectStationAndInstallationState, SortingOptions, SelectInvoiceState, SelectSessionState, SelectSessionTime } from 'common_library';
import { SelectGroupCategory, SelectIsAdmin } from 'common_library';
import { TranslateService } from '@ngx-translate/core';

export function getSortingOptions(translate: TranslateService) {
  return [
    { value: SortingOptions.ASC, label: translate.instant('FILTERS_OPTIONS.SORTING_OPTIONS.ASC') },
    { value: SortingOptions.DESC, label: translate.instant('FILTERS_OPTIONS.SORTING_OPTIONS.DESC') },
    { value: SortingOptions.NEW, label: translate.instant('FILTERS_OPTIONS.SORTING_OPTIONS.NEW') },
    { value: SortingOptions.OLD, label: translate.instant('FILTERS_OPTIONS.SORTING_OPTIONS.OLD') }
  ];
}
export function getSortingOptionsShort(translate: TranslateService) {
  return [
    { value: SortingOptions.NEW, label: translate.instant("FILTERS_OPTIONS.SORTING_OPTIONS_SHORT.NEW") },
    { value: SortingOptions.OLD, label: translate.instant("FILTERS_OPTIONS.SORTING_OPTIONS_SHORT.OLD") }
  ];
}
export function getStationInstallationState(translate: TranslateService) {
  return [
    { value: SelectStationAndInstallationState.NONE, label: translate.instant("FILTERS_OPTIONS.STATION_INSTALLATION_STATE.NONE") },
    { value: SelectStationAndInstallationState.NOT_AVAILABLE, label: translate.instant("FILTERS_OPTIONS.STATION_INSTALLATION_STATE.NOT_AVAILABLE") },
    { value: SelectStationAndInstallationState.AVAILABLE, label: translate.instant("FILTERS_OPTIONS.STATION_INSTALLATION_STATE.AVAILABLE") },
    { value: SelectStationAndInstallationState.RESERVED, label: translate.instant("FILTERS_OPTIONS.STATION_INSTALLATION_STATE.RESERVED") },
    { value: SelectStationAndInstallationState.IN_CHARGING, label: translate.instant("FILTERS_OPTIONS.STATION_INSTALLATION_STATE.IN_CHARGING") },
    { value: SelectStationAndInstallationState.OCCUPIED, label: translate.instant("FILTERS_OPTIONS.STATION_INSTALLATION_STATE.OCCUPIED") },
    { value: SelectStationAndInstallationState.PREPARING, label: translate.instant("FILTERS_OPTIONS.STATION_INSTALLATION_STATE.PREPARING") },
  ];
}
export function getConnectorsNumber(translate: TranslateService) {
  return [
    { value: SelectNumberOfConnector.NONE, label: translate.instant("FILTERS_OPTIONS.CONNECTORS_NUMBER.NONE") },
    { value: SelectNumberOfConnector.ONE, label: "1" },
    { value: SelectNumberOfConnector.TWO, label: "2" },
    { value: SelectNumberOfConnector.THREE, label: "3" },
    { value: SelectNumberOfConnector.FOUR, label: "4" },
  ];
}
export function getCPOType(translate: TranslateService) {
  return [
    { value: SelectCpo.NONE, label: translate.instant("FILTERS_OPTIONS.CPO_TYPE.NONE") },
    { value: SelectCpo.YFL, label: translate.instant("FILTERS_OPTIONS.CPO_TYPE.YFL") },
    { value: SelectCpo.ENX, label: translate.instant("FILTERS_OPTIONS.CPO_TYPE.ENX") },
    { value: SelectCpo.F2X, label: translate.instant("FILTERS_OPTIONS.CPO_TYPE.F2X") }
  ];
}
export function getOnlineState(translate: TranslateService) {
  return [
    { value: SelectOnlineStationState.NONE, label: translate.instant("FILTERS_OPTIONS.ONLINE_STATE.NONE") },
    { value: SelectOnlineStationState.ONLINE, label: translate.instant("FILTERS_OPTIONS.ONLINE_STATE.ONLINE") },
    { value: SelectOnlineStationState.OFFLINE, label: translate.instant("FILTERS_OPTIONS.ONLINE_STATE.OFFLINE") },
  ];
}
export function getCDRState(translate: TranslateService) {
  return [
    { value: SelectCDRState.NONE, label: translate.instant("FILTERS_OPTIONS.CDR_STATE.NONE") },
    { value: SelectCDRState.NEW, label: translate.instant("FILTERS_OPTIONS.CDR_STATE.NEW") },
    { value: SelectCDRState.IN_PROGRESS, label: translate.instant("FILTERS_OPTIONS.CDR_STATE.IN_PROGRESS") },
    { value: SelectCDRState.DONE, label: translate.instant("FILTERS_OPTIONS.CDR_STATE.DONE") },
    { value: SelectCDRState.FAILED, label: translate.instant("FILTERS_OPTIONS.CDR_STATE.FAILED") },
    { value: SelectCDRState.NOT_DUE, label: translate.instant("FILTERS_OPTIONS.CDR_STATE.NOT_DUE") },
    { value: SelectCDRState.IN_REVIEW, label: translate.instant("FILTERS_OPTIONS.CDR_STATE.IN_REVIEW") },
    { value: SelectCDRState.IN_REPROCESSING, label: translate.instant("FILTERS_OPTIONS.CDR_STATE.IN_REPROCESSING") }
  ];
}
export function getPaymentState(translate: TranslateService) {
  return [
    { value: SelectPaymentState.NONE, label: translate.instant("FILTERS_OPTIONS.PAYMENT_STATE.NONE") },
    { value: SelectPaymentState.NEW, label: translate.instant("FILTERS_OPTIONS.PAYMENT_STATE.NEW") },
    { value: SelectPaymentState.IN_PROGRESS, label: translate.instant("FILTERS_OPTIONS.PAYMENT_STATE.IN_PROGRESS") },
    { value: SelectPaymentState.IN_REPROCESSING, label: translate.instant("FILTERS_OPTIONS.PAYMENT_STATE.IN_REPROCESSING") },
    { value: SelectPaymentState.DONE, label: translate.instant("FILTERS_OPTIONS.PAYMENT_STATE.DONE") },
    { value: SelectPaymentState.FAILED, label: translate.instant("FILTERS_OPTIONS.PAYMENT_STATE.FAILED") },
    { value: SelectPaymentState.NOT_NECESSARY, label: translate.instant("FILTERS_OPTIONS.PAYMENT_STATE.NOT_NECESSARY") }
  ];
}
export function getCorrispettivoState(translate: TranslateService) {
  return [
    { value: SelectCorrispettivoState.NONE, label: translate.instant("FILTERS_OPTIONS.CORRISPETTIVO_STATE.NONE") },
    { value: SelectCorrispettivoState.NEW, label: translate.instant("FILTERS_OPTIONS.CORRISPETTIVO_STATE.NEW") },
    { value: SelectCorrispettivoState.IN_PROGRESS, label: translate.instant("FILTERS_OPTIONS.CORRISPETTIVO_STATE.IN_PROGRESS") },
    { value: SelectCorrispettivoState.DONE, label: translate.instant("FILTERS_OPTIONS.CORRISPETTIVO_STATE.DONE") },
    { value: SelectCorrispettivoState.FAILED, label: translate.instant("FILTERS_OPTIONS.CORRISPETTIVO_STATE.FAILED") },
    { value: SelectCorrispettivoState.IN_REPROCESSING, label: translate.instant("FILTERS_OPTIONS.CORRISPETTIVO_STATE.IN_REPROCESSING") },
    { value: SelectCorrispettivoState.CANCELED, label: translate.instant("FILTERS_OPTIONS.CORRISPETTIVO_STATE.CANCELED") },
    { value: SelectCorrispettivoState.NOT_NECESSARY, label: translate.instant("FILTERS_OPTIONS.CORRISPETTIVO_STATE.NOT_NECESSARY") },
    { value: SelectCorrispettivoState.IN_CANCELLATION, label: translate.instant("FILTERS_OPTIONS.CORRISPETTIVO_STATE.IN_CANCELLATION") }
  ];
}
export function getIsAdmin(translate: TranslateService) {
  return [
    { value: SelectIsAdmin.NONE, label: translate.instant("FILTERS_OPTIONS.IS_ADMIN.NONE") },
    { value: SelectIsAdmin.TRUE, label: translate.instant("FILTERS_OPTIONS.IS_ADMIN.TRUE") },
    { value: SelectIsAdmin.FALSE, label: translate.instant("FILTERS_OPTIONS.IS_ADMIN.FALSE") }
  ];
}
export function getGroupCategory(translate: TranslateService) {
  return [
    { value: SelectGroupCategory.NONE, label: translate.instant("FILTERS_OPTIONS.GROUP_CATEGORY.NONE") },
    { value: SelectGroupCategory.USERS, label: translate.instant("FILTERS_OPTIONS.GROUP_CATEGORY.USERS") },
    { value: SelectGroupCategory.STATIONS, label: translate.instant("FILTERS_OPTIONS.GROUP_CATEGORY.STATIONS") },
    { value: SelectGroupCategory.RATE, label: translate.instant("FILTERS_OPTIONS.GROUP_CATEGORY.RATE") },
    { value: SelectGroupCategory.VISIBILITY, label: translate.instant("FILTERS_OPTIONS.GROUP_CATEGORY.VISIBILITY") },
    { value: SelectGroupCategory.GROUPS, label: translate.instant("FILTERS_OPTIONS.GROUP_CATEGORY.GROUP") }
  ];
}
export function getInvoiceState(translate: TranslateService) {
  return [
    { value: SelectInvoiceState.NONE, label: translate.instant("FILTERS_OPTIONS.INVOICE_STATE.NONE") },
    { value: SelectInvoiceState.NEW, label: translate.instant("FILTERS_OPTIONS.INVOICE_STATE.NEW") },
    { value: SelectInvoiceState.IN_PROGRESS, label: translate.instant("FILTERS_OPTIONS.INVOICE_STATE.IN_PROGRESS") },
    { value: SelectInvoiceState.IN_REPROCESSING, label: translate.instant("FILTERS_OPTIONS.INVOICE_STATE.IN_REPROCESSING") },
    { value: SelectInvoiceState.DONE, label: translate.instant("FILTERS_OPTIONS.INVOICE_STATE.DONE") },
    { value: SelectInvoiceState.FAILED, label: translate.instant("FILTERS_OPTIONS.INVOICE_STATE.FAILED") },
    { value: SelectInvoiceState.REJECTED, label: translate.instant("FILTERS_OPTIONS.INVOICE_STATE.REJECTED") },
    { value: SelectInvoiceState.NOT_NECESSARY, label: translate.instant("FILTERS_OPTIONS.INVOICE_STATE.NOT_NECESSARY") }
  ];
}
export function getSessionState(translate: TranslateService) {
  return [
    { value: SelectSessionState.ALL, label: translate.instant("FILTERS_OPTIONS.SESSION_STATE.ALL") },
    { value: SelectSessionState.ACTIVE, label: translate.instant("FILTERS_OPTIONS.SESSION_STATE.ACTIVE") },
    { value: SelectSessionState.NOT_ACTIVE, label: translate.instant("FILTERS_OPTIONS.SESSION_STATE.NOT_ACTIVE") },
  ];
}
export function getSessionTimeInterval(translate: TranslateService) {
  return [
    { value: SelectSessionTime.TODAY, label: translate.instant("FILTERS_OPTIONS.SESSION_TIME_INTERVAL.TODAY") },
    { value: SelectSessionTime.YESTERDAY, label: translate.instant("FILTERS_OPTIONS.SESSION_TIME_INTERVAL.YESTERDAY") },
    { value: SelectSessionTime.THIS_WEEK, label: translate.instant("FILTERS_OPTIONS.SESSION_TIME_INTERVAL.THIS_WEEK") },
    { value: SelectSessionTime.LAST_WEEK, label: translate.instant("FILTERS_OPTIONS.SESSION_TIME_INTERVAL.LAST_WEEK") },
    { value: SelectSessionTime.CUSTOM_RANGE, label: translate.instant("FILTERS_OPTIONS.SESSION_TIME_INTERVAL.CUSTOM_RANGE") },
  ];
}