import { MeterValuesRequest, StartTransactionRequest, StatusNotificationRequest, StopTransactionRequest } from "@innovation-team/ep-ocpp-ts"
import { INumberIdEntity } from "./base.interface";

export namespace OCPPComunication {
  export type ValueOf<T> = T[keyof T];

  export const CpRequests = Object.freeze({
    CancelReservation: 'CancelReservation',
    ChangeAvailability: 'ChangeAvailability',
    ChangeConfiguration: 'ChangeConfiguration',
    ClearCache: 'ClearCache',
    ClearChargingProfile: 'ClearChargingProfile',
    DataTransfer: 'DataTransfer',
    GetCompositeSchedule: 'GetCompositeSchedule',
    GetConfiguration: 'GetConfiguration',
    GetDiagnostics: 'GetDiagnostics',
    GetLocalListVersion: 'GetLocalListVersion',
    RemoteStartTransaction: 'RemoteStartTransaction',
    RemoteStopTransaction: 'RemoteStopTransaction',
    ReserveNow: 'ReserveNow',
    Reset: 'Reset',
    SendLocalList: 'SendLocalList',
    SetChargingProfile: 'SetChargingProfile',
    TriggerMessage: 'TriggerMessage',
    UnlockConnector: 'UnlockConnector',
    UpdateFirmware: 'UpdateFirmware'
  });

  export type CpRequestsValues = ValueOf<typeof CpRequests>;
  export type CpRequestsKeys = keyof typeof CpRequests;

  export const InnerState = Object.freeze({
    Available: "Available",
    Preparing: "Preparing",
    Charging: "Charging",
    SuspendedEVSE: "SuspendedEVSE",
    SuspendedEV: "SuspendedEV",
    Finishing: "Finishing",
    Reserved: "Reserved",
    Unavailable: "Unavailable",
    Faulted: "Faulted"
  });

  export type InnerStateValues = ValueOf<typeof InnerState>;
  export type InnerStateKeys = keyof typeof InnerState;
  
  export interface IOcppGateway extends INumberIdEntity {
    name: string;
    accessToken: string;
    online: boolean;
    swVersion?: string;
    settings?: { [key: string]: any }
  }

  /* Eventi del gateway/socket */
  export interface GTWHandshakeData {
    name: string;
    accessToken: string;
    swVersion: string;
    settings?: { [key: string]: any }
  }

  /* Eventi delle colonnine */

  // export interface CPConnectionEvent {
  // 	id: string;
  // 	chargePoint: ChargePoint;
  // 	timestamp: Date;
  // }

  // Station inside OCPP
  export interface ChargePoint {
    id: string;
    lastHeartbeat?: Date;
  }

  export interface BootNotification {
    cpId: string;
    chargePointModel: string,
    chargePointVendor: string,
    chargeBoxSerialNumber: string,
    firmwareVersion: string,
    meterType: string
  }

  export interface Command {
    stationId: string;
  }

  export interface Message {
    stationId: string;
    topic: string;
    payload: any;
  }

  export interface BackendRequest<T> {
    payload: T;
    station: ChargePoint;
  }

  export interface BackendResponse<T> {
    success: boolean;
    payload: T;
  }

  export interface UpTime {
    stationId: string;
    fromTs: Date;
    updateTs?: Date;
  }

  export interface IOcppSession {
    id:string;
    cpId: string; 
    cpConnectorId:number;
    start?:StartTransactionRequest;
    stop?:StopTransactionRequest;
    states:StatusNotificationRequest[];
    values:MeterValuesRequest[];
  }
  
}