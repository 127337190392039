<div class="page-cont scrollbar-color">
  <ng-container *ngIf="sessions$ | async as sessions; else noDati">
    <div class="search-container yf-flex yf-flex-align-start">
      <div class="search-bar-filters">
        <app-search-bar class="search-bar" [padding]="false" [debounceTime]="400"
          placeholder="{{'SESSION.MAIN.SEARCH_SESSION'|translate}}" (textChange)="searchText$.next($event)"
          [text]="searchText$.getValue()">
        </app-search-bar>
        <button nzSpaceItem nz-button (click)="showSortingOptions()">
          <i nz-icon nzType="ordered-list" nzTheme="outline"></i>
        </button>

        <button nzSpaceItem nz-button (click)="showFilters()"
          [ngClass]="_isFilterActive ? 'filter-active' : 'filter-non-active'">
          <i nz-icon nzType="funnel-plot" nzTheme="outline"></i>
        </button>
      </div>

      <div class="pr-20 buttons-container">
        <ng-container *ngIf="!app.isMobile" [ngTemplateOutlet]="nzPaginationTemplate"></ng-container>
        <button (click)="refreshActiveSessions()" nzSpaceItem nz-button>
          <i nz-icon nzType="reload" nzTheme="outline"></i>
        </button>
      </div>
    </div>

    <nz-divider *ngIf="_showSortingOptions" nzOrientation="left"
      nzText="{{'FILTERS_OPTIONS.SORT'|translate}}"></nz-divider>
    <nz-divider *ngIf="_showFilters" nzOrientation="left" nzText="{{'FILTERS_OPTIONS.FILTER'|translate}}"></nz-divider>
    <div>
      <div *ngIf="_showSortingOptions" class="sorting-or-form-flex-start">
        <form [formGroup]="formSorting" class="pl-5">
          <formly-form [fields]="fieldsSorting" [form]="formSorting"></formly-form>
        </form>
      </div>

      <div *ngIf="_showFilters" class="filters-form-session">
        <form [formGroup]="formFilters" class="pl-5">
          <formly-form [fields]="fieldsFilters" [form]="formFilters"></formly-form>
        </form>
        @if((filterCriteria$ | async)?.sessionTimeSelect === SESSION_TIME_SELECT.CUSTOM_RANGE){
          <nz-range-picker nzFormat="dd/MM/yyyy" [(ngModel)]="customRangeValue" (nzOnCalendarChange)="dateRangeConfirmationEvent($event)"></nz-range-picker>
        }
        <div class="reset-filter-more-select">
          <button nz-button nzType="primary" nzDanger
            (click)="resetFilters()">{{'FILTERS_OPTIONS.FILTERS_RESET'|translate}}
          </button>
        </div>
      </div>
    </div>

    <nz-table *ngIf="!app.isMobile" #basicTable [nzData]="sessions.data" [nzPageSize]="20" [nzFrontPagination]="false"
      [nzShowPagination]="false" [nzScroll]="{y: getDynamicScrollHeight()}" nzSize="small">
      <thead>
        <tr>
          <th [nzWidth]="'40px'"></th>
          <th nzWidth="25%">{{'SESSION.MAIN.USER'|translate}}</th>
          <th>{{'SESSION.MAIN.STATE'|translate}}</th>
          <th [nzWidth]="'260px'">{{'SESSION.MAIN.STATION'|translate}}</th>
          <th>{{'SESSION.MAIN.DATE'|translate}}</th>
          <th>{{'SESSION.MAIN.DURATION'|translate}}</th>
          <th>{{'SESSION.MAIN.ENERGY'|translate}}</th>
          <th>{{'SESSION.MAIN.POWER'|translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data" routerLink="/authenticated/sessions/{{data.id}}">
          <!-- <td>
            <app-entity-tag [isSmallContainer]="true" [entityType]="'online'" [entity]="!(!!data.endedAt)"></app-entity-tag>
          </td>   -->
          <td>
            <span class="m-1" nz-icon nzType="thunderbolt" [nzTheme]="'twotone'"
              [nzTwotoneColor]="data.endedAt? '#ccc' : 'blue'" style="font-size: 14pt" nz-tooltip
              nzTooltipTitle="{{'NZTOOLTIPTITLE.ACTIVE_SESSION'|translate}}"></span>
          </td>
          <td class="user">
            <app-entity-tag [entity]="data.user" entityType="user"></app-entity-tag>
          </td>
          <td>
            <app-entity-tag [entity]="data.state" entityType="state"></app-entity-tag>
          </td>
          <td>
            <app-entity-tag [entity]="data.connector.station" entityType="station"></app-entity-tag>
          </td>
          <td>
            <span>
              {{ data.createdAt | localizedDate:"short" }}
            </span>
            <span *ngIf="data.endedAt">
              - {{ data.endedAt | localizedDate:"short" }}
            </span>
          </td>
          <td>
            <app-timespan [fromDate]="data.createdAt" [toDate]="data.endedAt"></app-timespan>
          </td>
          <td>
            {{data.energy | energy }} kWh
          </td>
          <td>
            {{data.power | power }} kW
          </td>

        </tr>
      </tbody>
    </nz-table>



    <div *ngIf="app.isMobile" class="yf-scroll-y cards-container" nz-space nzDirection="vertical" nzAlign="center">
      <ng-container *ngIf="!sessions.data || sessions.data.length === 0" [ngTemplateOutlet]="noDati"></ng-container>
      <nz-card class="mobile-card" *ngFor="let session of sessions.data"
        routerLink="/authenticated/sessions/{{session.id}}"
        nzTitle="{{session.user.surname}} {{session.user.name}}  {{ session.connector.station.name }}"
        [nzExtra]="extraIconsTemplate">
        <div>

          <!-- <app-block-values [data]="session" [mainFields]="['power']"></app-block-values> -->

          <app-block-value itemKey="user" label="{{'SESSION.MAIN.USER'|translate}}" [type]="'entity'"
            [value]="session.user"></app-block-value>
          <app-block-value itemKey="state" label="{{'SESSION.MAIN.STATE'|translate}}" [type]="'entity'"
            [value]="session.state"></app-block-value>
          <app-block-value itemKey="station" label="{{'SESSION.MAIN.STATION'|translate}}" [type]="'entity'"
            [value]="session.connector.station"></app-block-value>
          <app-block-value itemKey="createdAt" label="{{'SESSION.MAIN.CREATED'|translate}}"
            [value]="session.createdAt"></app-block-value>
          <app-block-value *ngIf="session.endedAt" itemKey="endedAt" label="{{'SESSION.MAIN.ENDED'|translate}}"
            [value]="session.endedAt"></app-block-value>
          <app-block-value itemKey="{{'SESSION.MAIN.ENERGY'|translate}}"
            value="{{session.energy | energy }} kWh"></app-block-value>
          <app-block-value itemKey="{{'SESSION.MAIN.POWER'|translate}}"
            value="{{session.power | power }} kW"></app-block-value>
          <app-block-value type="timespan" label="{{'SESSION.MAIN.DURATION'|translate}}"
            [value]="{from: session.createdAt, to: session.endedAt}"></app-block-value>
        </div>
        <ng-template #extraIconsTemplate>
          <app-status-icon iconType="thunderbolt" [isActive]="session.endedAt ? false : true"></app-status-icon>
        </ng-template>
      </nz-card>
    </div>

    <div class="mobile-footer" *ngIf="app.isMobile">
      <ng-container *ngTemplateOutlet="nzPaginationTemplate"></ng-container>
    </div>
    <ng-template #nzPaginationTemplate>
      @if((filterCriteria$ | async); as criteria){
      <nz-pagination [nzTotal]="sessions.count" [nzSize]="'small'" [nzPageSize]="20"
        (nzPageIndexChange)="pageIndexChange($event)" [nzPageIndex]="criteria.pageIndex">
        ></nz-pagination>
      }
    </ng-template>
  </ng-container>
</div>

<ng-template #noDati>
  <app-empty-data>
  </app-empty-data>
</ng-template>